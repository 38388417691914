import { Outlet, createRootRouteWithContext, redirect } from '@tanstack/react-router'
import { TanStackRouterDevtools } from '@tanstack/router-devtools'
import type { RouterContext } from '~/types/routerContext'
import type { RoutePath } from '~/types/shared'

const checkIfIsPublicRoute = (pathname: string) => {
  const publicRoutes: RoutePath[] = ['/login', '/logout']
  return publicRoutes.includes(pathname as RoutePath)
}

const RootComponent = () => {
  return (
    <>
      <Outlet />
      {import.meta.env.VITE_ENABLE_TANSTACK_ROUTER_DEVTOOLS === 'true' && (
        <TanStackRouterDevtools />
      )}
    </>
  )
}

export const Route = createRootRouteWithContext<RouterContext>()({
  component: RootComponent,
  beforeLoad: ({ context, location }) => {
    const isPublicRoute = checkIfIsPublicRoute(location.pathname)
    const shouldRedirect = !isPublicRoute && !context.auth.isAuthenticatedWithTenant

    if (shouldRedirect) {
      throw redirect({
        to: '/login',
        search: {
          redirect: location.href,
        },
        replace: true,
      })
    }
  },
})
