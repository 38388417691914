import { IconChevronsDown, IconChevronsRight } from '@tabler/icons-react'
import clsx from 'clsx'
import type { Ref } from 'react'
import { forwardRef, useEffect } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { IconButton } from '../IconButton'
import type { DrawerProps } from './types'

export const Drawer = forwardRef(function Drawer(
  {
    className,
    isOpen,
    position = 'right',
    onClose,
    disableDefaultWidth,
    disableDefaultHeight,
    children,
  }: DrawerProps,
  ref: Ref<HTMLDivElement>,
) {
  const { t } = useTranslation()

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose()
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [onClose])

  return createPortal(
    <div
      ref={ref}
      className={clsx(
        'fixed z-10 max-w-[100vw] bg-white shadow-md transition-all duration-200',
        position === 'right' && [
          'right-0 top-0 h-full rounded-tl-2xl',
          !disableDefaultWidth && 'w-full md:w-3/5 lg:w-2/5',
          !isOpen && 'translate-x-full',
        ],
        position === 'bottom' && [
          'bottom-0 left-0 w-full rounded-t-2xl',
          !disableDefaultHeight && 'h-[87%]',
          !isOpen && 'translate-y-full',
        ],
        className,
      )}
    >
      <IconButton
        onClick={onClose}
        className={clsx(
          'absolute z-1 m-2 !rounded-full p-1 !text-neutral-400 transition-all duration-200 hover:bg-neutral-100',
          position === 'bottom' ? 'right-0' : 'left-0',
        )}
        aria-label={t('closeModal')}
      >
        {position !== 'bottom' ? <IconChevronsRight /> : <IconChevronsDown />}
      </IconButton>
      <div className="h-full overflow-y-auto">{children}</div>
    </div>,
    document.body,
  )
})
