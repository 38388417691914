import { IconAlertTriangle, IconCircleCheck, IconCircleX } from '@tabler/icons-react'
import type { VariantProps } from 'class-variance-authority'
import { cva } from 'class-variance-authority'
import clsx from 'clsx'
import type { AlertProps } from './types'

const alertClasses = cva(
  'flex flex-row py-3 px-10 rounded-xl gap-2 items-center justify-center border',
  {
    variants: {
      status: {
        success: 'bg-emerald-100 text-emerald-700',
        error: 'bg-bittersweet-100 text-bittersweet-800',
        warning: 'bg-orangepeel-50 text-orangepeel-500',
      },
    },
  },
)

type ExtendedAlertProps = VariantProps<typeof alertClasses> & AlertProps

export const Alert = ({ children, className, icon, status = 'success' }: ExtendedAlertProps) => {
  const icons = {
    success: <IconCircleCheck />,
    error: <IconCircleX />,
    warning: <IconAlertTriangle />,
  }

  return (
    <div className={clsx(alertClasses({ status }), className)}>
      <div> {icon || (status && icons[status])} </div>

      {children && <div>{children}</div>}
    </div>
  )
}
