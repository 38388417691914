import { GridEditInputCell, type GridRenderEditCellParams } from '@mui/x-data-grid'

export const CustomEditCell = (params: GridRenderEditCellParams) => {
  return (
    <GridEditInputCell
      {...params}
      ref={(el) => {
        el?.querySelector('input')?.addEventListener('focus', (e) => {
          ;(e.target as HTMLInputElement)?.select()
        })
      }}
    />
  )
}
