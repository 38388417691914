import type { OptionalClassName } from '~/types'

export interface NoDataProps extends OptionalClassName {
  variant?: NoDataVariants
}

export enum NoDataVariants {
  VARIANT_1 = 'variant-1', // Background: seasalt
  VARIANT_2 = 'variant-2', // Background: white
}
