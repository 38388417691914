/**
 * Removes the trailing slash from a URL.
 *
 * @param url - The URL to remove the trailing slash from.
 * @returns The URL without the trailing slash.
 */
export function removeTrailingSlash(url: string) {
  return url.replace(/\/$/, '')
}

/**
 * Replace the trailing slash from a URL with a dot.
 *
 * @param url - The URL to remove the trailing slash from.
 * @returns The URL without the trailing slash and with a dot instead.
 */
export function replaceTrailingSlashWithDot(url: string) {
  return url.replace(/\//g, '.')
}

export const capitalizeFirstLetter = (str: string): string => {
  if (!str) return ''
  if (str.length === 0) return str
  return str.charAt(0).toUpperCase() + str.slice(1)
}
