import BugsnagPerformance from '@bugsnag/browser-performance'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import React from 'react'

export function initBugsnag() {
  Bugsnag.start({
    apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginReact()],
    appVersion: __COMMIT_HASH__,
    releaseStage: __DEPLOY_ENV__,
    enabledReleaseStages: __DEPLOY_ENVIRONMENTS__, // enable Bugsnag only in the specified deploy environments
  })
  BugsnagPerformance.start({ apiKey: import.meta.env.VITE_BUGSNAG_API_KEY })

  const ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React)

  return ErrorBoundary
}
