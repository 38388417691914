import Bugsnag from '@bugsnag/js'
import { type User, onAuthStateChanged } from 'firebase/auth'
import mixpanel from 'mixpanel-browser'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { usePrevious } from 'react-use'
import { Loader } from '~/components/UI/Loader'
import { useAuthLoginTenantQuery } from '~/hooks/queries/useAuthLoginTenantQuery'
import { useTenantModulesQuery } from '~/hooks/queries/useTenantModulesQuery'
import { useTenantUserRolesPermissionsQuery } from '~/hooks/queries/useTenantUserRolesPermissionsQuery'
import { useTenantsQuery } from '~/hooks/queries/useTenantsQuery'
import {
  firebaseAuth,
  signOut as firebaseSignOut,
  signInWithTenantInFirebase,
} from '~/lib/firebase'
import { queryClient } from '~/lib/queryClient'
import type { RoutePath } from '~/types/shared'
import { AuthContext } from './AuthContext'
import { checkModulePermission as checkModulePermissionFn, syncUserDetails } from './helpers'
import type { AuthContextValue, AuthProviderProps } from './types'

export const AuthProvider = (props: AuthProviderProps) => {
  const { forceAuthenticated = false, children } = props
  const [isInitialLoading, setIsInitialLoading] = useState(!props.forceAuthenticated)
  const [user, setUser] = useState<User | null>(null)
  const [tenantIdToLoad, setTenantIdToLoad] = useState<string | null>(null) // setting this will trigger the authLoginTenantQuery
  const [isSigningOut, setIsSigningOut] = useState(false)

  const authLoginTenantQuery = useAuthLoginTenantQuery(tenantIdToLoad)

  const { t } = useTranslation()

  const isAuthenticated = forceAuthenticated || !!user
  const isAuthenticatedWithTenant = forceAuthenticated || !!user?.tenantId
  const isAuthenticatingWithTenant = !!tenantIdToLoad || authLoginTenantQuery.isLoading
  const tenantId = user?.tenantId || null

  const tenantsQuery = useTenantsQuery({ enabled: !forceAuthenticated && isAuthenticated })

  const tenant = tenantsQuery.data?.tenants.find((tenant) => tenant.code === tenantId) || null
  const tenantUuid = tenant?.global_id || null

  const tenantModulesQuery = useTenantModulesQuery({
    tenantUuid,
    enabled: isAuthenticatedWithTenant,
  })
  const userTenantUserRolesPermissionsQuery = useTenantUserRolesPermissionsQuery({
    enabled: !forceAuthenticated && isAuthenticatedWithTenant,
  })

  const isLoadingTenantModules = tenantModulesQuery.isLoading
  const tenantModules = tenantModulesQuery.data?.modules || null

  const tenantDefaultLandingUrl = useMemo(
    () => userTenantUserRolesPermissionsQuery.data?.landingUrl || '',
    [userTenantUserRolesPermissionsQuery.data],
  )

  const tenantPermissions = useMemo(
    () => userTenantUserRolesPermissionsQuery.data?.permissions || [],
    [userTenantUserRolesPermissionsQuery.data],
  )

  const userGlobalId = useMemo(
    () => userTenantUserRolesPermissionsQuery.data?.userGlobalId || '',
    [userTenantUserRolesPermissionsQuery.data],
  )

  useEffect(() => {
    // Subscribe to Firebase auth state changes
    const unsubscribe = onAuthStateChanged(firebaseAuth, async (user) => {
      if (user) {
        const syncedUser = await syncUserDetails(user)
        setUser(syncedUser)

        const { email, displayName, photoURL } = syncedUser

        mixpanel.identify(user.uid)
        mixpanel.people.set({
          $email: email,
          $name: displayName,
          $avatar: photoURL,
        })

        if (email && displayName) {
          Bugsnag.setUser(user.uid, email, displayName)
        }
      } else {
        setUser(null)
        setTenantIdToLoad(null)
        Bugsnag.setUser() // clear Bugsnag user
      }

      setIsInitialLoading(false)
    })

    return unsubscribe
  }, [])

  const fetchLoginTenant = authLoginTenantQuery.refetch

  useEffect(() => {
    if (tenantIdToLoad) {
      fetchLoginTenant()
    }
  }, [fetchLoginTenant, tenantIdToLoad])

  useEffect(() => {
    if (!authLoginTenantQuery.data || forceAuthenticated) return

    const startSignInWithTenantInFirebase = async () => {
      const { token, provider_tenant_id } = authLoginTenantQuery.data
      const { user } = await signInWithTenantInFirebase(token, provider_tenant_id)
      const syncedUser = await syncUserDetails(user)
      setUser(syncedUser)

      setTenantIdToLoad(null) // once signed in with tenant, clear the tenantIdToLoad
    }

    startSignInWithTenantInFirebase()
  }, [authLoginTenantQuery.data, forceAuthenticated])

  const prevTenantId = usePrevious(tenantId)

  useEffect(() => {
    // when tenantId changes, invalidate all queries
    if (!forceAuthenticated && prevTenantId && prevTenantId !== tenantId) {
      queryClient.invalidateQueries({})
    }
  }, [prevTenantId, tenantId, forceAuthenticated])

  useEffect(() => {
    const companyMetadataKey = 'company'

    if (tenant) {
      Bugsnag.addMetadata(companyMetadataKey, {
        id: tenant.global_id,
        provider_tenant_id: tenant.code,
        name: tenant.company,
      })
    } else {
      Bugsnag.clearMetadata(companyMetadataKey)
    }
  }, [tenant])

  const signOut = useCallback(async () => {
    setIsSigningOut(true)

    await firebaseSignOut()
    queryClient.clear()
    localStorage.clear()
    mixpanel.reset()

    setIsSigningOut(false)
    // TODO: This is not working as expected, fix later, using window.location.href for now
    // navigate({ to: '/login', })
    window.location.href = '/login'
  }, [])

  const checkModulePermission = useCallback(
    (path: RoutePath) => checkModulePermissionFn(path, tenantPermissions),
    [tenantPermissions],
  )

  const authContextValue = useMemo(
    (): AuthContextValue => ({
      user,
      userGlobalId,
      isAuthenticated,
      isAuthenticatedWithTenant,
      tenant,
      tenantUuid,
      tenantId,
      tenantIdToLoad,
      setTenantIdToLoad,
      isAuthenticatingWithTenant,
      tenantModules,
      isLoadingTenantModules,
      checkModulePermission,
      tenantDefaultLandingUrl,
      signOut,
      isSigningOut,
    }),
    [
      user,
      userGlobalId,
      isAuthenticated,
      isAuthenticatedWithTenant,
      tenant,
      tenantUuid,
      tenantId,
      tenantIdToLoad,
      isAuthenticatingWithTenant,
      tenantModules,
      isLoadingTenantModules,
      checkModulePermission,
      tenantDefaultLandingUrl,
      signOut,
      isSigningOut,
    ],
  )

  if (isInitialLoading || userTenantUserRolesPermissionsQuery.isFetching) {
    return (
      <div className="flex h-screen w-screen items-center justify-center">
        <div className="flex flex-col items-center gap-4">
          <Loader iconSize={40} />
          <span>{t('statusMessage.verifiyingAuth')}... </span>
        </div>
      </div>
    )
  }

  return <AuthContext.Provider value={authContextValue}>{children}</AuthContext.Provider>
}
