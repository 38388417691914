import { IconChevronsUp } from '@tabler/icons-react'
import { type PropsWithChildren, type ReactNode, useRef, useState } from 'react'
import { useEvent } from 'react-use'
import { Drawer } from '../UI/Drawer'
import type { DrawerProps } from '../UI/Drawer/types'

interface ToggleDrawerButtonProps extends PropsWithChildren, Partial<DrawerProps> {
  title: string
  icon: ReactNode
  onToggle?: (isOpen: boolean) => void
}

export const ToggleDrawerButton = ({
  children,
  title,
  icon,
  onToggle,
  ...props
}: ToggleDrawerButtonProps) => {
  const [open, setOpen] = useState(false)

  const drawerRef = useRef<HTMLDivElement>(null)

  const handleClickOutside = (event: Event) => {
    if (drawerRef.current && !drawerRef.current.contains(event.target as Node)) {
      setOpen(false)
    }
  }

  useEvent('mousedown', handleClickOutside)

  const handleClose = () => {
    setOpen(false)
    onToggle?.(false)
  }

  const handleClick = () => {
    setOpen(true)
    onToggle?.(true)
  }

  return (
    <>
      <div
        className="absolute bottom-0 left-2/4 z-[8] flex h-14 w-[38%] -translate-x-1/2 transform cursor-pointer justify-between rounded-t-2xl bg-white py-4 pl-10 pr-6 shadow-2xl"
        onClick={handleClick}
      >
        <div className="flex h-6 items-center gap-4">
          <div className="h-full text-azure">{icon}</div>
          <span className="text-body-1-strong text-night-600">{title}</span>
        </div>
        <IconChevronsUp width={24} className="text-night-800" />
      </div>
      <Drawer
        {...props}
        isOpen={open}
        onClose={handleClose}
        position="bottom"
        ref={drawerRef}
        className="!shadow-2xl !shadow-night-800"
      >
        {children}
      </Drawer>
    </>
  )
}
