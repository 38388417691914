/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as LoginImport } from './routes/login'
import { Route as IndexImport } from './routes/index'
import { Route as LayoutWorkAreaImport } from './routes/_layout.work-area'
import { Route as LayoutAdministrationImport } from './routes/_layout.administration'
import { Route as LayoutReportsAndAnalyticsIndexImport } from './routes/_layout.reports-and-analytics.index'
import { Route as LayoutPlanningIndexImport } from './routes/_layout.planning.index'
import { Route as LayoutWorkAreaInventoryBalancingIndexImport } from './routes/_layout.work-area.inventory-balancing.index'
import { Route as LayoutPlanningDemandAndPromotionsIndexImport } from './routes/_layout.planning.demand-and-promotions.index'
import { Route as LayoutWorkAreaReplenishmentOrderIndexImport } from './routes/_layout.work-area.replenishment.order.index'
import { Route as LayoutWorkAreaProcurementOrderIndexImport } from './routes/_layout.work-area.procurement.order.index'
import { Route as LayoutWorkAreaProcurementDashboardIndexImport } from './routes/_layout.work-area.procurement.dashboard.index'
import { Route as LayoutPlanningScenariosCreateIndexImport } from './routes/_layout.planning.scenarios.create.index'
import { Route as LayoutPlanningDemandAndPromotionsDetailsIndexImport } from './routes/_layout.planning.demand-and-promotions.details.index'
import { Route as LayoutWorkAreaProcurementCalendarOrderIndexImport } from './routes/_layout.work-area.procurement.calendar.order.index'

// Create Virtual Routes

const LogoutLazyImport = createFileRoute('/logout')()
const LayoutLazyImport = createFileRoute('/_layout')()
const LayoutDashboardIndexLazyImport = createFileRoute('/_layout/dashboard/')()
const LayoutWorkAreaSurplursManagementIndexLazyImport = createFileRoute(
  '/_layout/work-area/surplurs-management/',
)()
const LayoutWorkAreaReplenishmentIndexLazyImport = createFileRoute(
  '/_layout/work-area/replenishment/',
)()
const LayoutWorkAreaProcurementIndexLazyImport = createFileRoute(
  '/_layout/work-area/procurement/',
)()
const LayoutWorkAreaPriceDefinitionIndexLazyImport = createFileRoute(
  '/_layout/work-area/price-definition/',
)()
const LayoutReportsAndAnalyticsStockPerformanceIndexLazyImport =
  createFileRoute('/_layout/reports-and-analytics/stock-performance/')()
const LayoutReportsAndAnalyticsOverallPerformanceIndexLazyImport =
  createFileRoute('/_layout/reports-and-analytics/overall-performance/')()
const LayoutReportsAndAnalyticsForecastPerformanceIndexLazyImport =
  createFileRoute('/_layout/reports-and-analytics/forecast-performance/')()
const LayoutReportsAndAnalyticsCommercialPerformanceIndexLazyImport =
  createFileRoute('/_layout/reports-and-analytics/commercial-performance/')()
const LayoutReportsAndAnalyticsAdherencePerformanceIndexLazyImport =
  createFileRoute('/_layout/reports-and-analytics/adherence-performance/')()
const LayoutPlanningScenariosIndexLazyImport = createFileRoute(
  '/_layout/planning/scenarios/',
)()
const LayoutPlanningPromotionalCampaignsIndexLazyImport = createFileRoute(
  '/_layout/planning/promotional-campaigns/',
)()
const LayoutPlanningPromotionalCalendarIndexLazyImport = createFileRoute(
  '/_layout/planning/promotional-calendar/',
)()
const LayoutPlanningProcurementCalendarIndexLazyImport = createFileRoute(
  '/_layout/planning/procurement-calendar/',
)()
const LayoutPlanningInvitedSuppliersIndexLazyImport = createFileRoute(
  '/_layout/planning/invited-suppliers/',
)()
const LayoutPlanningEventsCalendarIndexLazyImport = createFileRoute(
  '/_layout/planning/events-calendar/',
)()
const LayoutAdministrationUsersIndexLazyImport = createFileRoute(
  '/_layout/administration/users/',
)()
const LayoutAdministrationRolesAndPermissionsIndexLazyImport = createFileRoute(
  '/_layout/administration/roles-and-permissions/',
)()
const LayoutAdministrationGeneralSettingsIndexLazyImport = createFileRoute(
  '/_layout/administration/general-settings/',
)()
const LayoutAdministrationGeneralParametersIndexLazyImport = createFileRoute(
  '/_layout/administration/general-parameters/',
)()
const LayoutAdministrationEnvironmentVariablesIndexLazyImport = createFileRoute(
  '/_layout/administration/environment-variables/',
)()
const LayoutAdministrationDataPermissionIndexLazyImport = createFileRoute(
  '/_layout/administration/data-permission/',
)()
const LayoutAdministrationDataAllocationIndexLazyImport = createFileRoute(
  '/_layout/administration/data-allocation/',
)()
const LayoutAdministrationConfigColumnsIndexLazyImport = createFileRoute(
  '/_layout/administration/config-columns/',
)()
const LayoutAdministrationColumnGroupsIndexLazyImport = createFileRoute(
  '/_layout/administration/column-groups/',
)()
const LayoutAdministrationAppFiltersIndexLazyImport = createFileRoute(
  '/_layout/administration/app-filters/',
)()
const LayoutWorkAreaReplenishmentSuggestionsIndexLazyImport = createFileRoute(
  '/_layout/work-area/replenishment/suggestions/',
)()
const LayoutWorkAreaProcurementCalendarIndexLazyImport = createFileRoute(
  '/_layout/work-area/procurement/calendar/',
)()
const LayoutReportsAndAnalyticsStockPerformanceProcurementIndexLazyImport =
  createFileRoute(
    '/_layout/reports-and-analytics/stock-performance/procurement/',
  )()
const LayoutReportsAndAnalyticsStockPerformanceOverstockIndexLazyImport =
  createFileRoute(
    '/_layout/reports-and-analytics/stock-performance/overstock/',
  )()
const LayoutReportsAndAnalyticsStockPerformanceMetricsIndexLazyImport =
  createFileRoute('/_layout/reports-and-analytics/stock-performance/metrics/')()
const LayoutReportsAndAnalyticsStockPerformanceLostSaleIndexLazyImport =
  createFileRoute(
    '/_layout/reports-and-analytics/stock-performance/lost-sale/',
  )()
const LayoutPlanningScenariosScenarioIdIndexLazyImport = createFileRoute(
  '/_layout/planning/scenarios/$scenarioId/',
)()
const LayoutPlanningPromotionalCampaignsCampaignIdIndexLazyImport =
  createFileRoute('/_layout/planning/promotional-campaigns/$campaignId/')()
const LayoutAdministrationUsersUserIdIndexLazyImport = createFileRoute(
  '/_layout/administration/users/$userId/',
)()
const LayoutAdministrationColumnGroupsColumnGroupIdIndexLazyImport =
  createFileRoute('/_layout/administration/column-groups/$columnGroupId/')()
const LayoutPlanningPromotionalCampaignsCampaignIdPromotionPromotionIdIndexLazyImport =
  createFileRoute(
    '/_layout/planning/promotional-campaigns/$campaignId/promotion/$promotionId/',
  )()

// Create/Update Routes

const LogoutLazyRoute = LogoutLazyImport.update({
  id: '/logout',
  path: '/logout',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/logout.lazy').then((d) => d.Route))

const LayoutLazyRoute = LayoutLazyImport.update({
  id: '/_layout',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/_layout.lazy').then((d) => d.Route))

const LoginRoute = LoginImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const LayoutWorkAreaRoute = LayoutWorkAreaImport.update({
  id: '/work-area',
  path: '/work-area',
  getParentRoute: () => LayoutLazyRoute,
} as any).lazy(() =>
  import('./routes/_layout.work-area.lazy').then((d) => d.Route),
)

const LayoutAdministrationRoute = LayoutAdministrationImport.update({
  id: '/administration',
  path: '/administration',
  getParentRoute: () => LayoutLazyRoute,
} as any).lazy(() =>
  import('./routes/_layout.administration.lazy').then((d) => d.Route),
)

const LayoutDashboardIndexLazyRoute = LayoutDashboardIndexLazyImport.update({
  id: '/dashboard/',
  path: '/dashboard/',
  getParentRoute: () => LayoutLazyRoute,
} as any).lazy(() =>
  import('./routes/_layout.dashboard.index.lazy').then((d) => d.Route),
)

const LayoutReportsAndAnalyticsIndexRoute =
  LayoutReportsAndAnalyticsIndexImport.update({
    id: '/reports-and-analytics/',
    path: '/reports-and-analytics/',
    getParentRoute: () => LayoutLazyRoute,
  } as any)

const LayoutPlanningIndexRoute = LayoutPlanningIndexImport.update({
  id: '/planning/',
  path: '/planning/',
  getParentRoute: () => LayoutLazyRoute,
} as any).lazy(() =>
  import('./routes/_layout.planning.index.lazy').then((d) => d.Route),
)

const LayoutWorkAreaSurplursManagementIndexLazyRoute =
  LayoutWorkAreaSurplursManagementIndexLazyImport.update({
    id: '/surplurs-management/',
    path: '/surplurs-management/',
    getParentRoute: () => LayoutWorkAreaRoute,
  } as any).lazy(() =>
    import('./routes/_layout.work-area.surplurs-management.index.lazy').then(
      (d) => d.Route,
    ),
  )

const LayoutWorkAreaReplenishmentIndexLazyRoute =
  LayoutWorkAreaReplenishmentIndexLazyImport.update({
    id: '/replenishment/',
    path: '/replenishment/',
    getParentRoute: () => LayoutWorkAreaRoute,
  } as any).lazy(() =>
    import('./routes/_layout.work-area.replenishment.index.lazy').then(
      (d) => d.Route,
    ),
  )

const LayoutWorkAreaProcurementIndexLazyRoute =
  LayoutWorkAreaProcurementIndexLazyImport.update({
    id: '/procurement/',
    path: '/procurement/',
    getParentRoute: () => LayoutWorkAreaRoute,
  } as any).lazy(() =>
    import('./routes/_layout.work-area.procurement.index.lazy').then(
      (d) => d.Route,
    ),
  )

const LayoutWorkAreaPriceDefinitionIndexLazyRoute =
  LayoutWorkAreaPriceDefinitionIndexLazyImport.update({
    id: '/price-definition/',
    path: '/price-definition/',
    getParentRoute: () => LayoutWorkAreaRoute,
  } as any).lazy(() =>
    import('./routes/_layout.work-area.price-definition.index.lazy').then(
      (d) => d.Route,
    ),
  )

const LayoutReportsAndAnalyticsStockPerformanceIndexLazyRoute =
  LayoutReportsAndAnalyticsStockPerformanceIndexLazyImport.update({
    id: '/reports-and-analytics/stock-performance/',
    path: '/reports-and-analytics/stock-performance/',
    getParentRoute: () => LayoutLazyRoute,
  } as any).lazy(() =>
    import(
      './routes/_layout.reports-and-analytics.stock-performance.index.lazy'
    ).then((d) => d.Route),
  )

const LayoutReportsAndAnalyticsOverallPerformanceIndexLazyRoute =
  LayoutReportsAndAnalyticsOverallPerformanceIndexLazyImport.update({
    id: '/reports-and-analytics/overall-performance/',
    path: '/reports-and-analytics/overall-performance/',
    getParentRoute: () => LayoutLazyRoute,
  } as any).lazy(() =>
    import(
      './routes/_layout.reports-and-analytics.overall-performance.index.lazy'
    ).then((d) => d.Route),
  )

const LayoutReportsAndAnalyticsForecastPerformanceIndexLazyRoute =
  LayoutReportsAndAnalyticsForecastPerformanceIndexLazyImport.update({
    id: '/reports-and-analytics/forecast-performance/',
    path: '/reports-and-analytics/forecast-performance/',
    getParentRoute: () => LayoutLazyRoute,
  } as any).lazy(() =>
    import(
      './routes/_layout.reports-and-analytics.forecast-performance.index.lazy'
    ).then((d) => d.Route),
  )

const LayoutReportsAndAnalyticsCommercialPerformanceIndexLazyRoute =
  LayoutReportsAndAnalyticsCommercialPerformanceIndexLazyImport.update({
    id: '/reports-and-analytics/commercial-performance/',
    path: '/reports-and-analytics/commercial-performance/',
    getParentRoute: () => LayoutLazyRoute,
  } as any).lazy(() =>
    import(
      './routes/_layout.reports-and-analytics.commercial-performance.index.lazy'
    ).then((d) => d.Route),
  )

const LayoutReportsAndAnalyticsAdherencePerformanceIndexLazyRoute =
  LayoutReportsAndAnalyticsAdherencePerformanceIndexLazyImport.update({
    id: '/reports-and-analytics/adherence-performance/',
    path: '/reports-and-analytics/adherence-performance/',
    getParentRoute: () => LayoutLazyRoute,
  } as any).lazy(() =>
    import(
      './routes/_layout.reports-and-analytics.adherence-performance.index.lazy'
    ).then((d) => d.Route),
  )

const LayoutPlanningScenariosIndexLazyRoute =
  LayoutPlanningScenariosIndexLazyImport.update({
    id: '/planning/scenarios/',
    path: '/planning/scenarios/',
    getParentRoute: () => LayoutLazyRoute,
  } as any).lazy(() =>
    import('./routes/_layout.planning.scenarios.index.lazy').then(
      (d) => d.Route,
    ),
  )

const LayoutPlanningPromotionalCampaignsIndexLazyRoute =
  LayoutPlanningPromotionalCampaignsIndexLazyImport.update({
    id: '/planning/promotional-campaigns/',
    path: '/planning/promotional-campaigns/',
    getParentRoute: () => LayoutLazyRoute,
  } as any).lazy(() =>
    import('./routes/_layout.planning.promotional-campaigns.index.lazy').then(
      (d) => d.Route,
    ),
  )

const LayoutPlanningPromotionalCalendarIndexLazyRoute =
  LayoutPlanningPromotionalCalendarIndexLazyImport.update({
    id: '/planning/promotional-calendar/',
    path: '/planning/promotional-calendar/',
    getParentRoute: () => LayoutLazyRoute,
  } as any).lazy(() =>
    import('./routes/_layout.planning.promotional-calendar.index.lazy').then(
      (d) => d.Route,
    ),
  )

const LayoutPlanningProcurementCalendarIndexLazyRoute =
  LayoutPlanningProcurementCalendarIndexLazyImport.update({
    id: '/planning/procurement-calendar/',
    path: '/planning/procurement-calendar/',
    getParentRoute: () => LayoutLazyRoute,
  } as any).lazy(() =>
    import('./routes/_layout.planning.procurement-calendar.index.lazy').then(
      (d) => d.Route,
    ),
  )

const LayoutPlanningInvitedSuppliersIndexLazyRoute =
  LayoutPlanningInvitedSuppliersIndexLazyImport.update({
    id: '/planning/invited-suppliers/',
    path: '/planning/invited-suppliers/',
    getParentRoute: () => LayoutLazyRoute,
  } as any).lazy(() =>
    import('./routes/_layout.planning.invited-suppliers.index.lazy').then(
      (d) => d.Route,
    ),
  )

const LayoutPlanningEventsCalendarIndexLazyRoute =
  LayoutPlanningEventsCalendarIndexLazyImport.update({
    id: '/planning/events-calendar/',
    path: '/planning/events-calendar/',
    getParentRoute: () => LayoutLazyRoute,
  } as any).lazy(() =>
    import('./routes/_layout.planning.events-calendar.index.lazy').then(
      (d) => d.Route,
    ),
  )

const LayoutAdministrationUsersIndexLazyRoute =
  LayoutAdministrationUsersIndexLazyImport.update({
    id: '/users/',
    path: '/users/',
    getParentRoute: () => LayoutAdministrationRoute,
  } as any).lazy(() =>
    import('./routes/_layout.administration.users.index.lazy').then(
      (d) => d.Route,
    ),
  )

const LayoutAdministrationRolesAndPermissionsIndexLazyRoute =
  LayoutAdministrationRolesAndPermissionsIndexLazyImport.update({
    id: '/roles-and-permissions/',
    path: '/roles-and-permissions/',
    getParentRoute: () => LayoutAdministrationRoute,
  } as any).lazy(() =>
    import(
      './routes/_layout.administration.roles-and-permissions.index.lazy'
    ).then((d) => d.Route),
  )

const LayoutAdministrationGeneralSettingsIndexLazyRoute =
  LayoutAdministrationGeneralSettingsIndexLazyImport.update({
    id: '/general-settings/',
    path: '/general-settings/',
    getParentRoute: () => LayoutAdministrationRoute,
  } as any).lazy(() =>
    import('./routes/_layout.administration.general-settings.index.lazy').then(
      (d) => d.Route,
    ),
  )

const LayoutAdministrationGeneralParametersIndexLazyRoute =
  LayoutAdministrationGeneralParametersIndexLazyImport.update({
    id: '/general-parameters/',
    path: '/general-parameters/',
    getParentRoute: () => LayoutAdministrationRoute,
  } as any).lazy(() =>
    import(
      './routes/_layout.administration.general-parameters.index.lazy'
    ).then((d) => d.Route),
  )

const LayoutAdministrationEnvironmentVariablesIndexLazyRoute =
  LayoutAdministrationEnvironmentVariablesIndexLazyImport.update({
    id: '/environment-variables/',
    path: '/environment-variables/',
    getParentRoute: () => LayoutAdministrationRoute,
  } as any).lazy(() =>
    import(
      './routes/_layout.administration.environment-variables.index.lazy'
    ).then((d) => d.Route),
  )

const LayoutAdministrationDataPermissionIndexLazyRoute =
  LayoutAdministrationDataPermissionIndexLazyImport.update({
    id: '/data-permission/',
    path: '/data-permission/',
    getParentRoute: () => LayoutAdministrationRoute,
  } as any).lazy(() =>
    import('./routes/_layout.administration.data-permission.index.lazy').then(
      (d) => d.Route,
    ),
  )

const LayoutAdministrationDataAllocationIndexLazyRoute =
  LayoutAdministrationDataAllocationIndexLazyImport.update({
    id: '/data-allocation/',
    path: '/data-allocation/',
    getParentRoute: () => LayoutAdministrationRoute,
  } as any).lazy(() =>
    import('./routes/_layout.administration.data-allocation.index.lazy').then(
      (d) => d.Route,
    ),
  )

const LayoutAdministrationConfigColumnsIndexLazyRoute =
  LayoutAdministrationConfigColumnsIndexLazyImport.update({
    id: '/config-columns/',
    path: '/config-columns/',
    getParentRoute: () => LayoutAdministrationRoute,
  } as any).lazy(() =>
    import('./routes/_layout.administration.config-columns.index.lazy').then(
      (d) => d.Route,
    ),
  )

const LayoutAdministrationColumnGroupsIndexLazyRoute =
  LayoutAdministrationColumnGroupsIndexLazyImport.update({
    id: '/column-groups/',
    path: '/column-groups/',
    getParentRoute: () => LayoutAdministrationRoute,
  } as any).lazy(() =>
    import('./routes/_layout.administration.column-groups.index.lazy').then(
      (d) => d.Route,
    ),
  )

const LayoutAdministrationAppFiltersIndexLazyRoute =
  LayoutAdministrationAppFiltersIndexLazyImport.update({
    id: '/app-filters/',
    path: '/app-filters/',
    getParentRoute: () => LayoutAdministrationRoute,
  } as any).lazy(() =>
    import('./routes/_layout.administration.app-filters.index.lazy').then(
      (d) => d.Route,
    ),
  )

const LayoutWorkAreaInventoryBalancingIndexRoute =
  LayoutWorkAreaInventoryBalancingIndexImport.update({
    id: '/inventory-balancing/',
    path: '/inventory-balancing/',
    getParentRoute: () => LayoutWorkAreaRoute,
  } as any)

const LayoutPlanningDemandAndPromotionsIndexRoute =
  LayoutPlanningDemandAndPromotionsIndexImport.update({
    id: '/planning/demand-and-promotions/',
    path: '/planning/demand-and-promotions/',
    getParentRoute: () => LayoutLazyRoute,
  } as any).lazy(() =>
    import('./routes/_layout.planning.demand-and-promotions.index.lazy').then(
      (d) => d.Route,
    ),
  )

const LayoutWorkAreaReplenishmentSuggestionsIndexLazyRoute =
  LayoutWorkAreaReplenishmentSuggestionsIndexLazyImport.update({
    id: '/replenishment/suggestions/',
    path: '/replenishment/suggestions/',
    getParentRoute: () => LayoutWorkAreaRoute,
  } as any).lazy(() =>
    import(
      './routes/_layout.work-area.replenishment.suggestions.index.lazy'
    ).then((d) => d.Route),
  )

const LayoutWorkAreaProcurementCalendarIndexLazyRoute =
  LayoutWorkAreaProcurementCalendarIndexLazyImport.update({
    id: '/procurement/calendar/',
    path: '/procurement/calendar/',
    getParentRoute: () => LayoutWorkAreaRoute,
  } as any).lazy(() =>
    import('./routes/_layout.work-area.procurement.calendar.index.lazy').then(
      (d) => d.Route,
    ),
  )

const LayoutReportsAndAnalyticsStockPerformanceProcurementIndexLazyRoute =
  LayoutReportsAndAnalyticsStockPerformanceProcurementIndexLazyImport.update({
    id: '/reports-and-analytics/stock-performance/procurement/',
    path: '/reports-and-analytics/stock-performance/procurement/',
    getParentRoute: () => LayoutLazyRoute,
  } as any).lazy(() =>
    import(
      './routes/_layout.reports-and-analytics.stock-performance.procurement.index.lazy'
    ).then((d) => d.Route),
  )

const LayoutReportsAndAnalyticsStockPerformanceOverstockIndexLazyRoute =
  LayoutReportsAndAnalyticsStockPerformanceOverstockIndexLazyImport.update({
    id: '/reports-and-analytics/stock-performance/overstock/',
    path: '/reports-and-analytics/stock-performance/overstock/',
    getParentRoute: () => LayoutLazyRoute,
  } as any).lazy(() =>
    import(
      './routes/_layout.reports-and-analytics.stock-performance.overstock.index.lazy'
    ).then((d) => d.Route),
  )

const LayoutReportsAndAnalyticsStockPerformanceMetricsIndexLazyRoute =
  LayoutReportsAndAnalyticsStockPerformanceMetricsIndexLazyImport.update({
    id: '/reports-and-analytics/stock-performance/metrics/',
    path: '/reports-and-analytics/stock-performance/metrics/',
    getParentRoute: () => LayoutLazyRoute,
  } as any).lazy(() =>
    import(
      './routes/_layout.reports-and-analytics.stock-performance.metrics.index.lazy'
    ).then((d) => d.Route),
  )

const LayoutReportsAndAnalyticsStockPerformanceLostSaleIndexLazyRoute =
  LayoutReportsAndAnalyticsStockPerformanceLostSaleIndexLazyImport.update({
    id: '/reports-and-analytics/stock-performance/lost-sale/',
    path: '/reports-and-analytics/stock-performance/lost-sale/',
    getParentRoute: () => LayoutLazyRoute,
  } as any).lazy(() =>
    import(
      './routes/_layout.reports-and-analytics.stock-performance.lost-sale.index.lazy'
    ).then((d) => d.Route),
  )

const LayoutPlanningScenariosScenarioIdIndexLazyRoute =
  LayoutPlanningScenariosScenarioIdIndexLazyImport.update({
    id: '/planning/scenarios/$scenarioId/',
    path: '/planning/scenarios/$scenarioId/',
    getParentRoute: () => LayoutLazyRoute,
  } as any).lazy(() =>
    import('./routes/_layout.planning.scenarios.$scenarioId.index.lazy').then(
      (d) => d.Route,
    ),
  )

const LayoutPlanningPromotionalCampaignsCampaignIdIndexLazyRoute =
  LayoutPlanningPromotionalCampaignsCampaignIdIndexLazyImport.update({
    id: '/planning/promotional-campaigns/$campaignId/',
    path: '/planning/promotional-campaigns/$campaignId/',
    getParentRoute: () => LayoutLazyRoute,
  } as any).lazy(() =>
    import(
      './routes/_layout.planning.promotional-campaigns.$campaignId.index.lazy'
    ).then((d) => d.Route),
  )

const LayoutAdministrationUsersUserIdIndexLazyRoute =
  LayoutAdministrationUsersUserIdIndexLazyImport.update({
    id: '/users/$userId/',
    path: '/users/$userId/',
    getParentRoute: () => LayoutAdministrationRoute,
  } as any).lazy(() =>
    import('./routes/_layout.administration.users.$userId.index.lazy').then(
      (d) => d.Route,
    ),
  )

const LayoutAdministrationColumnGroupsColumnGroupIdIndexLazyRoute =
  LayoutAdministrationColumnGroupsColumnGroupIdIndexLazyImport.update({
    id: '/column-groups/$columnGroupId/',
    path: '/column-groups/$columnGroupId/',
    getParentRoute: () => LayoutAdministrationRoute,
  } as any).lazy(() =>
    import(
      './routes/_layout.administration.column-groups.$columnGroupId.index.lazy'
    ).then((d) => d.Route),
  )

const LayoutWorkAreaReplenishmentOrderIndexRoute =
  LayoutWorkAreaReplenishmentOrderIndexImport.update({
    id: '/replenishment/order/',
    path: '/replenishment/order/',
    getParentRoute: () => LayoutWorkAreaRoute,
  } as any).lazy(() =>
    import('./routes/_layout.work-area.replenishment.order.index.lazy').then(
      (d) => d.Route,
    ),
  )

const LayoutWorkAreaProcurementOrderIndexRoute =
  LayoutWorkAreaProcurementOrderIndexImport.update({
    id: '/procurement/order/',
    path: '/procurement/order/',
    getParentRoute: () => LayoutWorkAreaRoute,
  } as any).lazy(() =>
    import('./routes/_layout.work-area.procurement.order.index.lazy').then(
      (d) => d.Route,
    ),
  )

const LayoutWorkAreaProcurementDashboardIndexRoute =
  LayoutWorkAreaProcurementDashboardIndexImport.update({
    id: '/procurement/dashboard/',
    path: '/procurement/dashboard/',
    getParentRoute: () => LayoutWorkAreaRoute,
  } as any).lazy(() =>
    import('./routes/_layout.work-area.procurement.dashboard.index.lazy').then(
      (d) => d.Route,
    ),
  )

const LayoutPlanningScenariosCreateIndexRoute =
  LayoutPlanningScenariosCreateIndexImport.update({
    id: '/planning/scenarios/create/',
    path: '/planning/scenarios/create/',
    getParentRoute: () => LayoutLazyRoute,
  } as any).lazy(() =>
    import('./routes/_layout.planning.scenarios.create.index.lazy').then(
      (d) => d.Route,
    ),
  )

const LayoutPlanningDemandAndPromotionsDetailsIndexRoute =
  LayoutPlanningDemandAndPromotionsDetailsIndexImport.update({
    id: '/planning/demand-and-promotions/details/',
    path: '/planning/demand-and-promotions/details/',
    getParentRoute: () => LayoutLazyRoute,
  } as any).lazy(() =>
    import(
      './routes/_layout.planning.demand-and-promotions.details.index.lazy'
    ).then((d) => d.Route),
  )

const LayoutWorkAreaProcurementCalendarOrderIndexRoute =
  LayoutWorkAreaProcurementCalendarOrderIndexImport.update({
    id: '/procurement/calendar/order/',
    path: '/procurement/calendar/order/',
    getParentRoute: () => LayoutWorkAreaRoute,
  } as any).lazy(() =>
    import(
      './routes/_layout.work-area.procurement.calendar.order.index.lazy'
    ).then((d) => d.Route),
  )

const LayoutPlanningPromotionalCampaignsCampaignIdPromotionPromotionIdIndexLazyRoute =
  LayoutPlanningPromotionalCampaignsCampaignIdPromotionPromotionIdIndexLazyImport.update(
    {
      id: '/planning/promotional-campaigns/$campaignId/promotion/$promotionId/',
      path: '/planning/promotional-campaigns/$campaignId/promotion/$promotionId/',
      getParentRoute: () => LayoutLazyRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/_layout.planning.promotional-campaigns.$campaignId.promotion.$promotionId.index.lazy'
    ).then((d) => d.Route),
  )

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/login': {
      id: '/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof LoginImport
      parentRoute: typeof rootRoute
    }
    '/_layout': {
      id: '/_layout'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof LayoutLazyImport
      parentRoute: typeof rootRoute
    }
    '/logout': {
      id: '/logout'
      path: '/logout'
      fullPath: '/logout'
      preLoaderRoute: typeof LogoutLazyImport
      parentRoute: typeof rootRoute
    }
    '/_layout/administration': {
      id: '/_layout/administration'
      path: '/administration'
      fullPath: '/administration'
      preLoaderRoute: typeof LayoutAdministrationImport
      parentRoute: typeof LayoutLazyImport
    }
    '/_layout/work-area': {
      id: '/_layout/work-area'
      path: '/work-area'
      fullPath: '/work-area'
      preLoaderRoute: typeof LayoutWorkAreaImport
      parentRoute: typeof LayoutLazyImport
    }
    '/_layout/planning/': {
      id: '/_layout/planning/'
      path: '/planning'
      fullPath: '/planning'
      preLoaderRoute: typeof LayoutPlanningIndexImport
      parentRoute: typeof LayoutLazyImport
    }
    '/_layout/reports-and-analytics/': {
      id: '/_layout/reports-and-analytics/'
      path: '/reports-and-analytics'
      fullPath: '/reports-and-analytics'
      preLoaderRoute: typeof LayoutReportsAndAnalyticsIndexImport
      parentRoute: typeof LayoutLazyImport
    }
    '/_layout/dashboard/': {
      id: '/_layout/dashboard/'
      path: '/dashboard'
      fullPath: '/dashboard'
      preLoaderRoute: typeof LayoutDashboardIndexLazyImport
      parentRoute: typeof LayoutLazyImport
    }
    '/_layout/planning/demand-and-promotions/': {
      id: '/_layout/planning/demand-and-promotions/'
      path: '/planning/demand-and-promotions'
      fullPath: '/planning/demand-and-promotions'
      preLoaderRoute: typeof LayoutPlanningDemandAndPromotionsIndexImport
      parentRoute: typeof LayoutLazyImport
    }
    '/_layout/work-area/inventory-balancing/': {
      id: '/_layout/work-area/inventory-balancing/'
      path: '/inventory-balancing'
      fullPath: '/work-area/inventory-balancing'
      preLoaderRoute: typeof LayoutWorkAreaInventoryBalancingIndexImport
      parentRoute: typeof LayoutWorkAreaImport
    }
    '/_layout/administration/app-filters/': {
      id: '/_layout/administration/app-filters/'
      path: '/app-filters'
      fullPath: '/administration/app-filters'
      preLoaderRoute: typeof LayoutAdministrationAppFiltersIndexLazyImport
      parentRoute: typeof LayoutAdministrationImport
    }
    '/_layout/administration/column-groups/': {
      id: '/_layout/administration/column-groups/'
      path: '/column-groups'
      fullPath: '/administration/column-groups'
      preLoaderRoute: typeof LayoutAdministrationColumnGroupsIndexLazyImport
      parentRoute: typeof LayoutAdministrationImport
    }
    '/_layout/administration/config-columns/': {
      id: '/_layout/administration/config-columns/'
      path: '/config-columns'
      fullPath: '/administration/config-columns'
      preLoaderRoute: typeof LayoutAdministrationConfigColumnsIndexLazyImport
      parentRoute: typeof LayoutAdministrationImport
    }
    '/_layout/administration/data-allocation/': {
      id: '/_layout/administration/data-allocation/'
      path: '/data-allocation'
      fullPath: '/administration/data-allocation'
      preLoaderRoute: typeof LayoutAdministrationDataAllocationIndexLazyImport
      parentRoute: typeof LayoutAdministrationImport
    }
    '/_layout/administration/data-permission/': {
      id: '/_layout/administration/data-permission/'
      path: '/data-permission'
      fullPath: '/administration/data-permission'
      preLoaderRoute: typeof LayoutAdministrationDataPermissionIndexLazyImport
      parentRoute: typeof LayoutAdministrationImport
    }
    '/_layout/administration/environment-variables/': {
      id: '/_layout/administration/environment-variables/'
      path: '/environment-variables'
      fullPath: '/administration/environment-variables'
      preLoaderRoute: typeof LayoutAdministrationEnvironmentVariablesIndexLazyImport
      parentRoute: typeof LayoutAdministrationImport
    }
    '/_layout/administration/general-parameters/': {
      id: '/_layout/administration/general-parameters/'
      path: '/general-parameters'
      fullPath: '/administration/general-parameters'
      preLoaderRoute: typeof LayoutAdministrationGeneralParametersIndexLazyImport
      parentRoute: typeof LayoutAdministrationImport
    }
    '/_layout/administration/general-settings/': {
      id: '/_layout/administration/general-settings/'
      path: '/general-settings'
      fullPath: '/administration/general-settings'
      preLoaderRoute: typeof LayoutAdministrationGeneralSettingsIndexLazyImport
      parentRoute: typeof LayoutAdministrationImport
    }
    '/_layout/administration/roles-and-permissions/': {
      id: '/_layout/administration/roles-and-permissions/'
      path: '/roles-and-permissions'
      fullPath: '/administration/roles-and-permissions'
      preLoaderRoute: typeof LayoutAdministrationRolesAndPermissionsIndexLazyImport
      parentRoute: typeof LayoutAdministrationImport
    }
    '/_layout/administration/users/': {
      id: '/_layout/administration/users/'
      path: '/users'
      fullPath: '/administration/users'
      preLoaderRoute: typeof LayoutAdministrationUsersIndexLazyImport
      parentRoute: typeof LayoutAdministrationImport
    }
    '/_layout/planning/events-calendar/': {
      id: '/_layout/planning/events-calendar/'
      path: '/planning/events-calendar'
      fullPath: '/planning/events-calendar'
      preLoaderRoute: typeof LayoutPlanningEventsCalendarIndexLazyImport
      parentRoute: typeof LayoutLazyImport
    }
    '/_layout/planning/invited-suppliers/': {
      id: '/_layout/planning/invited-suppliers/'
      path: '/planning/invited-suppliers'
      fullPath: '/planning/invited-suppliers'
      preLoaderRoute: typeof LayoutPlanningInvitedSuppliersIndexLazyImport
      parentRoute: typeof LayoutLazyImport
    }
    '/_layout/planning/procurement-calendar/': {
      id: '/_layout/planning/procurement-calendar/'
      path: '/planning/procurement-calendar'
      fullPath: '/planning/procurement-calendar'
      preLoaderRoute: typeof LayoutPlanningProcurementCalendarIndexLazyImport
      parentRoute: typeof LayoutLazyImport
    }
    '/_layout/planning/promotional-calendar/': {
      id: '/_layout/planning/promotional-calendar/'
      path: '/planning/promotional-calendar'
      fullPath: '/planning/promotional-calendar'
      preLoaderRoute: typeof LayoutPlanningPromotionalCalendarIndexLazyImport
      parentRoute: typeof LayoutLazyImport
    }
    '/_layout/planning/promotional-campaigns/': {
      id: '/_layout/planning/promotional-campaigns/'
      path: '/planning/promotional-campaigns'
      fullPath: '/planning/promotional-campaigns'
      preLoaderRoute: typeof LayoutPlanningPromotionalCampaignsIndexLazyImport
      parentRoute: typeof LayoutLazyImport
    }
    '/_layout/planning/scenarios/': {
      id: '/_layout/planning/scenarios/'
      path: '/planning/scenarios'
      fullPath: '/planning/scenarios'
      preLoaderRoute: typeof LayoutPlanningScenariosIndexLazyImport
      parentRoute: typeof LayoutLazyImport
    }
    '/_layout/reports-and-analytics/adherence-performance/': {
      id: '/_layout/reports-and-analytics/adherence-performance/'
      path: '/reports-and-analytics/adherence-performance'
      fullPath: '/reports-and-analytics/adherence-performance'
      preLoaderRoute: typeof LayoutReportsAndAnalyticsAdherencePerformanceIndexLazyImport
      parentRoute: typeof LayoutLazyImport
    }
    '/_layout/reports-and-analytics/commercial-performance/': {
      id: '/_layout/reports-and-analytics/commercial-performance/'
      path: '/reports-and-analytics/commercial-performance'
      fullPath: '/reports-and-analytics/commercial-performance'
      preLoaderRoute: typeof LayoutReportsAndAnalyticsCommercialPerformanceIndexLazyImport
      parentRoute: typeof LayoutLazyImport
    }
    '/_layout/reports-and-analytics/forecast-performance/': {
      id: '/_layout/reports-and-analytics/forecast-performance/'
      path: '/reports-and-analytics/forecast-performance'
      fullPath: '/reports-and-analytics/forecast-performance'
      preLoaderRoute: typeof LayoutReportsAndAnalyticsForecastPerformanceIndexLazyImport
      parentRoute: typeof LayoutLazyImport
    }
    '/_layout/reports-and-analytics/overall-performance/': {
      id: '/_layout/reports-and-analytics/overall-performance/'
      path: '/reports-and-analytics/overall-performance'
      fullPath: '/reports-and-analytics/overall-performance'
      preLoaderRoute: typeof LayoutReportsAndAnalyticsOverallPerformanceIndexLazyImport
      parentRoute: typeof LayoutLazyImport
    }
    '/_layout/reports-and-analytics/stock-performance/': {
      id: '/_layout/reports-and-analytics/stock-performance/'
      path: '/reports-and-analytics/stock-performance'
      fullPath: '/reports-and-analytics/stock-performance'
      preLoaderRoute: typeof LayoutReportsAndAnalyticsStockPerformanceIndexLazyImport
      parentRoute: typeof LayoutLazyImport
    }
    '/_layout/work-area/price-definition/': {
      id: '/_layout/work-area/price-definition/'
      path: '/price-definition'
      fullPath: '/work-area/price-definition'
      preLoaderRoute: typeof LayoutWorkAreaPriceDefinitionIndexLazyImport
      parentRoute: typeof LayoutWorkAreaImport
    }
    '/_layout/work-area/procurement/': {
      id: '/_layout/work-area/procurement/'
      path: '/procurement'
      fullPath: '/work-area/procurement'
      preLoaderRoute: typeof LayoutWorkAreaProcurementIndexLazyImport
      parentRoute: typeof LayoutWorkAreaImport
    }
    '/_layout/work-area/replenishment/': {
      id: '/_layout/work-area/replenishment/'
      path: '/replenishment'
      fullPath: '/work-area/replenishment'
      preLoaderRoute: typeof LayoutWorkAreaReplenishmentIndexLazyImport
      parentRoute: typeof LayoutWorkAreaImport
    }
    '/_layout/work-area/surplurs-management/': {
      id: '/_layout/work-area/surplurs-management/'
      path: '/surplurs-management'
      fullPath: '/work-area/surplurs-management'
      preLoaderRoute: typeof LayoutWorkAreaSurplursManagementIndexLazyImport
      parentRoute: typeof LayoutWorkAreaImport
    }
    '/_layout/planning/demand-and-promotions/details/': {
      id: '/_layout/planning/demand-and-promotions/details/'
      path: '/planning/demand-and-promotions/details'
      fullPath: '/planning/demand-and-promotions/details'
      preLoaderRoute: typeof LayoutPlanningDemandAndPromotionsDetailsIndexImport
      parentRoute: typeof LayoutLazyImport
    }
    '/_layout/planning/scenarios/create/': {
      id: '/_layout/planning/scenarios/create/'
      path: '/planning/scenarios/create'
      fullPath: '/planning/scenarios/create'
      preLoaderRoute: typeof LayoutPlanningScenariosCreateIndexImport
      parentRoute: typeof LayoutLazyImport
    }
    '/_layout/work-area/procurement/dashboard/': {
      id: '/_layout/work-area/procurement/dashboard/'
      path: '/procurement/dashboard'
      fullPath: '/work-area/procurement/dashboard'
      preLoaderRoute: typeof LayoutWorkAreaProcurementDashboardIndexImport
      parentRoute: typeof LayoutWorkAreaImport
    }
    '/_layout/work-area/procurement/order/': {
      id: '/_layout/work-area/procurement/order/'
      path: '/procurement/order'
      fullPath: '/work-area/procurement/order'
      preLoaderRoute: typeof LayoutWorkAreaProcurementOrderIndexImport
      parentRoute: typeof LayoutWorkAreaImport
    }
    '/_layout/work-area/replenishment/order/': {
      id: '/_layout/work-area/replenishment/order/'
      path: '/replenishment/order'
      fullPath: '/work-area/replenishment/order'
      preLoaderRoute: typeof LayoutWorkAreaReplenishmentOrderIndexImport
      parentRoute: typeof LayoutWorkAreaImport
    }
    '/_layout/administration/column-groups/$columnGroupId/': {
      id: '/_layout/administration/column-groups/$columnGroupId/'
      path: '/column-groups/$columnGroupId'
      fullPath: '/administration/column-groups/$columnGroupId'
      preLoaderRoute: typeof LayoutAdministrationColumnGroupsColumnGroupIdIndexLazyImport
      parentRoute: typeof LayoutAdministrationImport
    }
    '/_layout/administration/users/$userId/': {
      id: '/_layout/administration/users/$userId/'
      path: '/users/$userId'
      fullPath: '/administration/users/$userId'
      preLoaderRoute: typeof LayoutAdministrationUsersUserIdIndexLazyImport
      parentRoute: typeof LayoutAdministrationImport
    }
    '/_layout/planning/promotional-campaigns/$campaignId/': {
      id: '/_layout/planning/promotional-campaigns/$campaignId/'
      path: '/planning/promotional-campaigns/$campaignId'
      fullPath: '/planning/promotional-campaigns/$campaignId'
      preLoaderRoute: typeof LayoutPlanningPromotionalCampaignsCampaignIdIndexLazyImport
      parentRoute: typeof LayoutLazyImport
    }
    '/_layout/planning/scenarios/$scenarioId/': {
      id: '/_layout/planning/scenarios/$scenarioId/'
      path: '/planning/scenarios/$scenarioId'
      fullPath: '/planning/scenarios/$scenarioId'
      preLoaderRoute: typeof LayoutPlanningScenariosScenarioIdIndexLazyImport
      parentRoute: typeof LayoutLazyImport
    }
    '/_layout/reports-and-analytics/stock-performance/lost-sale/': {
      id: '/_layout/reports-and-analytics/stock-performance/lost-sale/'
      path: '/reports-and-analytics/stock-performance/lost-sale'
      fullPath: '/reports-and-analytics/stock-performance/lost-sale'
      preLoaderRoute: typeof LayoutReportsAndAnalyticsStockPerformanceLostSaleIndexLazyImport
      parentRoute: typeof LayoutLazyImport
    }
    '/_layout/reports-and-analytics/stock-performance/metrics/': {
      id: '/_layout/reports-and-analytics/stock-performance/metrics/'
      path: '/reports-and-analytics/stock-performance/metrics'
      fullPath: '/reports-and-analytics/stock-performance/metrics'
      preLoaderRoute: typeof LayoutReportsAndAnalyticsStockPerformanceMetricsIndexLazyImport
      parentRoute: typeof LayoutLazyImport
    }
    '/_layout/reports-and-analytics/stock-performance/overstock/': {
      id: '/_layout/reports-and-analytics/stock-performance/overstock/'
      path: '/reports-and-analytics/stock-performance/overstock'
      fullPath: '/reports-and-analytics/stock-performance/overstock'
      preLoaderRoute: typeof LayoutReportsAndAnalyticsStockPerformanceOverstockIndexLazyImport
      parentRoute: typeof LayoutLazyImport
    }
    '/_layout/reports-and-analytics/stock-performance/procurement/': {
      id: '/_layout/reports-and-analytics/stock-performance/procurement/'
      path: '/reports-and-analytics/stock-performance/procurement'
      fullPath: '/reports-and-analytics/stock-performance/procurement'
      preLoaderRoute: typeof LayoutReportsAndAnalyticsStockPerformanceProcurementIndexLazyImport
      parentRoute: typeof LayoutLazyImport
    }
    '/_layout/work-area/procurement/calendar/': {
      id: '/_layout/work-area/procurement/calendar/'
      path: '/procurement/calendar'
      fullPath: '/work-area/procurement/calendar'
      preLoaderRoute: typeof LayoutWorkAreaProcurementCalendarIndexLazyImport
      parentRoute: typeof LayoutWorkAreaImport
    }
    '/_layout/work-area/replenishment/suggestions/': {
      id: '/_layout/work-area/replenishment/suggestions/'
      path: '/replenishment/suggestions'
      fullPath: '/work-area/replenishment/suggestions'
      preLoaderRoute: typeof LayoutWorkAreaReplenishmentSuggestionsIndexLazyImport
      parentRoute: typeof LayoutWorkAreaImport
    }
    '/_layout/work-area/procurement/calendar/order/': {
      id: '/_layout/work-area/procurement/calendar/order/'
      path: '/procurement/calendar/order'
      fullPath: '/work-area/procurement/calendar/order'
      preLoaderRoute: typeof LayoutWorkAreaProcurementCalendarOrderIndexImport
      parentRoute: typeof LayoutWorkAreaImport
    }
    '/_layout/planning/promotional-campaigns/$campaignId/promotion/$promotionId/': {
      id: '/_layout/planning/promotional-campaigns/$campaignId/promotion/$promotionId/'
      path: '/planning/promotional-campaigns/$campaignId/promotion/$promotionId'
      fullPath: '/planning/promotional-campaigns/$campaignId/promotion/$promotionId'
      preLoaderRoute: typeof LayoutPlanningPromotionalCampaignsCampaignIdPromotionPromotionIdIndexLazyImport
      parentRoute: typeof LayoutLazyImport
    }
  }
}

// Create and export the route tree

interface LayoutAdministrationRouteChildren {
  LayoutAdministrationAppFiltersIndexLazyRoute: typeof LayoutAdministrationAppFiltersIndexLazyRoute
  LayoutAdministrationColumnGroupsIndexLazyRoute: typeof LayoutAdministrationColumnGroupsIndexLazyRoute
  LayoutAdministrationConfigColumnsIndexLazyRoute: typeof LayoutAdministrationConfigColumnsIndexLazyRoute
  LayoutAdministrationDataAllocationIndexLazyRoute: typeof LayoutAdministrationDataAllocationIndexLazyRoute
  LayoutAdministrationDataPermissionIndexLazyRoute: typeof LayoutAdministrationDataPermissionIndexLazyRoute
  LayoutAdministrationEnvironmentVariablesIndexLazyRoute: typeof LayoutAdministrationEnvironmentVariablesIndexLazyRoute
  LayoutAdministrationGeneralParametersIndexLazyRoute: typeof LayoutAdministrationGeneralParametersIndexLazyRoute
  LayoutAdministrationGeneralSettingsIndexLazyRoute: typeof LayoutAdministrationGeneralSettingsIndexLazyRoute
  LayoutAdministrationRolesAndPermissionsIndexLazyRoute: typeof LayoutAdministrationRolesAndPermissionsIndexLazyRoute
  LayoutAdministrationUsersIndexLazyRoute: typeof LayoutAdministrationUsersIndexLazyRoute
  LayoutAdministrationColumnGroupsColumnGroupIdIndexLazyRoute: typeof LayoutAdministrationColumnGroupsColumnGroupIdIndexLazyRoute
  LayoutAdministrationUsersUserIdIndexLazyRoute: typeof LayoutAdministrationUsersUserIdIndexLazyRoute
}

const LayoutAdministrationRouteChildren: LayoutAdministrationRouteChildren = {
  LayoutAdministrationAppFiltersIndexLazyRoute:
    LayoutAdministrationAppFiltersIndexLazyRoute,
  LayoutAdministrationColumnGroupsIndexLazyRoute:
    LayoutAdministrationColumnGroupsIndexLazyRoute,
  LayoutAdministrationConfigColumnsIndexLazyRoute:
    LayoutAdministrationConfigColumnsIndexLazyRoute,
  LayoutAdministrationDataAllocationIndexLazyRoute:
    LayoutAdministrationDataAllocationIndexLazyRoute,
  LayoutAdministrationDataPermissionIndexLazyRoute:
    LayoutAdministrationDataPermissionIndexLazyRoute,
  LayoutAdministrationEnvironmentVariablesIndexLazyRoute:
    LayoutAdministrationEnvironmentVariablesIndexLazyRoute,
  LayoutAdministrationGeneralParametersIndexLazyRoute:
    LayoutAdministrationGeneralParametersIndexLazyRoute,
  LayoutAdministrationGeneralSettingsIndexLazyRoute:
    LayoutAdministrationGeneralSettingsIndexLazyRoute,
  LayoutAdministrationRolesAndPermissionsIndexLazyRoute:
    LayoutAdministrationRolesAndPermissionsIndexLazyRoute,
  LayoutAdministrationUsersIndexLazyRoute:
    LayoutAdministrationUsersIndexLazyRoute,
  LayoutAdministrationColumnGroupsColumnGroupIdIndexLazyRoute:
    LayoutAdministrationColumnGroupsColumnGroupIdIndexLazyRoute,
  LayoutAdministrationUsersUserIdIndexLazyRoute:
    LayoutAdministrationUsersUserIdIndexLazyRoute,
}

const LayoutAdministrationRouteWithChildren =
  LayoutAdministrationRoute._addFileChildren(LayoutAdministrationRouteChildren)

interface LayoutWorkAreaRouteChildren {
  LayoutWorkAreaInventoryBalancingIndexRoute: typeof LayoutWorkAreaInventoryBalancingIndexRoute
  LayoutWorkAreaPriceDefinitionIndexLazyRoute: typeof LayoutWorkAreaPriceDefinitionIndexLazyRoute
  LayoutWorkAreaProcurementIndexLazyRoute: typeof LayoutWorkAreaProcurementIndexLazyRoute
  LayoutWorkAreaReplenishmentIndexLazyRoute: typeof LayoutWorkAreaReplenishmentIndexLazyRoute
  LayoutWorkAreaSurplursManagementIndexLazyRoute: typeof LayoutWorkAreaSurplursManagementIndexLazyRoute
  LayoutWorkAreaProcurementDashboardIndexRoute: typeof LayoutWorkAreaProcurementDashboardIndexRoute
  LayoutWorkAreaProcurementOrderIndexRoute: typeof LayoutWorkAreaProcurementOrderIndexRoute
  LayoutWorkAreaReplenishmentOrderIndexRoute: typeof LayoutWorkAreaReplenishmentOrderIndexRoute
  LayoutWorkAreaProcurementCalendarIndexLazyRoute: typeof LayoutWorkAreaProcurementCalendarIndexLazyRoute
  LayoutWorkAreaReplenishmentSuggestionsIndexLazyRoute: typeof LayoutWorkAreaReplenishmentSuggestionsIndexLazyRoute
  LayoutWorkAreaProcurementCalendarOrderIndexRoute: typeof LayoutWorkAreaProcurementCalendarOrderIndexRoute
}

const LayoutWorkAreaRouteChildren: LayoutWorkAreaRouteChildren = {
  LayoutWorkAreaInventoryBalancingIndexRoute:
    LayoutWorkAreaInventoryBalancingIndexRoute,
  LayoutWorkAreaPriceDefinitionIndexLazyRoute:
    LayoutWorkAreaPriceDefinitionIndexLazyRoute,
  LayoutWorkAreaProcurementIndexLazyRoute:
    LayoutWorkAreaProcurementIndexLazyRoute,
  LayoutWorkAreaReplenishmentIndexLazyRoute:
    LayoutWorkAreaReplenishmentIndexLazyRoute,
  LayoutWorkAreaSurplursManagementIndexLazyRoute:
    LayoutWorkAreaSurplursManagementIndexLazyRoute,
  LayoutWorkAreaProcurementDashboardIndexRoute:
    LayoutWorkAreaProcurementDashboardIndexRoute,
  LayoutWorkAreaProcurementOrderIndexRoute:
    LayoutWorkAreaProcurementOrderIndexRoute,
  LayoutWorkAreaReplenishmentOrderIndexRoute:
    LayoutWorkAreaReplenishmentOrderIndexRoute,
  LayoutWorkAreaProcurementCalendarIndexLazyRoute:
    LayoutWorkAreaProcurementCalendarIndexLazyRoute,
  LayoutWorkAreaReplenishmentSuggestionsIndexLazyRoute:
    LayoutWorkAreaReplenishmentSuggestionsIndexLazyRoute,
  LayoutWorkAreaProcurementCalendarOrderIndexRoute:
    LayoutWorkAreaProcurementCalendarOrderIndexRoute,
}

const LayoutWorkAreaRouteWithChildren = LayoutWorkAreaRoute._addFileChildren(
  LayoutWorkAreaRouteChildren,
)

interface LayoutLazyRouteChildren {
  LayoutAdministrationRoute: typeof LayoutAdministrationRouteWithChildren
  LayoutWorkAreaRoute: typeof LayoutWorkAreaRouteWithChildren
  LayoutPlanningIndexRoute: typeof LayoutPlanningIndexRoute
  LayoutReportsAndAnalyticsIndexRoute: typeof LayoutReportsAndAnalyticsIndexRoute
  LayoutDashboardIndexLazyRoute: typeof LayoutDashboardIndexLazyRoute
  LayoutPlanningDemandAndPromotionsIndexRoute: typeof LayoutPlanningDemandAndPromotionsIndexRoute
  LayoutPlanningEventsCalendarIndexLazyRoute: typeof LayoutPlanningEventsCalendarIndexLazyRoute
  LayoutPlanningInvitedSuppliersIndexLazyRoute: typeof LayoutPlanningInvitedSuppliersIndexLazyRoute
  LayoutPlanningProcurementCalendarIndexLazyRoute: typeof LayoutPlanningProcurementCalendarIndexLazyRoute
  LayoutPlanningPromotionalCalendarIndexLazyRoute: typeof LayoutPlanningPromotionalCalendarIndexLazyRoute
  LayoutPlanningPromotionalCampaignsIndexLazyRoute: typeof LayoutPlanningPromotionalCampaignsIndexLazyRoute
  LayoutPlanningScenariosIndexLazyRoute: typeof LayoutPlanningScenariosIndexLazyRoute
  LayoutReportsAndAnalyticsAdherencePerformanceIndexLazyRoute: typeof LayoutReportsAndAnalyticsAdherencePerformanceIndexLazyRoute
  LayoutReportsAndAnalyticsCommercialPerformanceIndexLazyRoute: typeof LayoutReportsAndAnalyticsCommercialPerformanceIndexLazyRoute
  LayoutReportsAndAnalyticsForecastPerformanceIndexLazyRoute: typeof LayoutReportsAndAnalyticsForecastPerformanceIndexLazyRoute
  LayoutReportsAndAnalyticsOverallPerformanceIndexLazyRoute: typeof LayoutReportsAndAnalyticsOverallPerformanceIndexLazyRoute
  LayoutReportsAndAnalyticsStockPerformanceIndexLazyRoute: typeof LayoutReportsAndAnalyticsStockPerformanceIndexLazyRoute
  LayoutPlanningDemandAndPromotionsDetailsIndexRoute: typeof LayoutPlanningDemandAndPromotionsDetailsIndexRoute
  LayoutPlanningScenariosCreateIndexRoute: typeof LayoutPlanningScenariosCreateIndexRoute
  LayoutPlanningPromotionalCampaignsCampaignIdIndexLazyRoute: typeof LayoutPlanningPromotionalCampaignsCampaignIdIndexLazyRoute
  LayoutPlanningScenariosScenarioIdIndexLazyRoute: typeof LayoutPlanningScenariosScenarioIdIndexLazyRoute
  LayoutReportsAndAnalyticsStockPerformanceLostSaleIndexLazyRoute: typeof LayoutReportsAndAnalyticsStockPerformanceLostSaleIndexLazyRoute
  LayoutReportsAndAnalyticsStockPerformanceMetricsIndexLazyRoute: typeof LayoutReportsAndAnalyticsStockPerformanceMetricsIndexLazyRoute
  LayoutReportsAndAnalyticsStockPerformanceOverstockIndexLazyRoute: typeof LayoutReportsAndAnalyticsStockPerformanceOverstockIndexLazyRoute
  LayoutReportsAndAnalyticsStockPerformanceProcurementIndexLazyRoute: typeof LayoutReportsAndAnalyticsStockPerformanceProcurementIndexLazyRoute
  LayoutPlanningPromotionalCampaignsCampaignIdPromotionPromotionIdIndexLazyRoute: typeof LayoutPlanningPromotionalCampaignsCampaignIdPromotionPromotionIdIndexLazyRoute
}

const LayoutLazyRouteChildren: LayoutLazyRouteChildren = {
  LayoutAdministrationRoute: LayoutAdministrationRouteWithChildren,
  LayoutWorkAreaRoute: LayoutWorkAreaRouteWithChildren,
  LayoutPlanningIndexRoute: LayoutPlanningIndexRoute,
  LayoutReportsAndAnalyticsIndexRoute: LayoutReportsAndAnalyticsIndexRoute,
  LayoutDashboardIndexLazyRoute: LayoutDashboardIndexLazyRoute,
  LayoutPlanningDemandAndPromotionsIndexRoute:
    LayoutPlanningDemandAndPromotionsIndexRoute,
  LayoutPlanningEventsCalendarIndexLazyRoute:
    LayoutPlanningEventsCalendarIndexLazyRoute,
  LayoutPlanningInvitedSuppliersIndexLazyRoute:
    LayoutPlanningInvitedSuppliersIndexLazyRoute,
  LayoutPlanningProcurementCalendarIndexLazyRoute:
    LayoutPlanningProcurementCalendarIndexLazyRoute,
  LayoutPlanningPromotionalCalendarIndexLazyRoute:
    LayoutPlanningPromotionalCalendarIndexLazyRoute,
  LayoutPlanningPromotionalCampaignsIndexLazyRoute:
    LayoutPlanningPromotionalCampaignsIndexLazyRoute,
  LayoutPlanningScenariosIndexLazyRoute: LayoutPlanningScenariosIndexLazyRoute,
  LayoutReportsAndAnalyticsAdherencePerformanceIndexLazyRoute:
    LayoutReportsAndAnalyticsAdherencePerformanceIndexLazyRoute,
  LayoutReportsAndAnalyticsCommercialPerformanceIndexLazyRoute:
    LayoutReportsAndAnalyticsCommercialPerformanceIndexLazyRoute,
  LayoutReportsAndAnalyticsForecastPerformanceIndexLazyRoute:
    LayoutReportsAndAnalyticsForecastPerformanceIndexLazyRoute,
  LayoutReportsAndAnalyticsOverallPerformanceIndexLazyRoute:
    LayoutReportsAndAnalyticsOverallPerformanceIndexLazyRoute,
  LayoutReportsAndAnalyticsStockPerformanceIndexLazyRoute:
    LayoutReportsAndAnalyticsStockPerformanceIndexLazyRoute,
  LayoutPlanningDemandAndPromotionsDetailsIndexRoute:
    LayoutPlanningDemandAndPromotionsDetailsIndexRoute,
  LayoutPlanningScenariosCreateIndexRoute:
    LayoutPlanningScenariosCreateIndexRoute,
  LayoutPlanningPromotionalCampaignsCampaignIdIndexLazyRoute:
    LayoutPlanningPromotionalCampaignsCampaignIdIndexLazyRoute,
  LayoutPlanningScenariosScenarioIdIndexLazyRoute:
    LayoutPlanningScenariosScenarioIdIndexLazyRoute,
  LayoutReportsAndAnalyticsStockPerformanceLostSaleIndexLazyRoute:
    LayoutReportsAndAnalyticsStockPerformanceLostSaleIndexLazyRoute,
  LayoutReportsAndAnalyticsStockPerformanceMetricsIndexLazyRoute:
    LayoutReportsAndAnalyticsStockPerformanceMetricsIndexLazyRoute,
  LayoutReportsAndAnalyticsStockPerformanceOverstockIndexLazyRoute:
    LayoutReportsAndAnalyticsStockPerformanceOverstockIndexLazyRoute,
  LayoutReportsAndAnalyticsStockPerformanceProcurementIndexLazyRoute:
    LayoutReportsAndAnalyticsStockPerformanceProcurementIndexLazyRoute,
  LayoutPlanningPromotionalCampaignsCampaignIdPromotionPromotionIdIndexLazyRoute:
    LayoutPlanningPromotionalCampaignsCampaignIdPromotionPromotionIdIndexLazyRoute,
}

const LayoutLazyRouteWithChildren = LayoutLazyRoute._addFileChildren(
  LayoutLazyRouteChildren,
)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '/login': typeof LoginRoute
  '': typeof LayoutLazyRouteWithChildren
  '/logout': typeof LogoutLazyRoute
  '/administration': typeof LayoutAdministrationRouteWithChildren
  '/work-area': typeof LayoutWorkAreaRouteWithChildren
  '/planning': typeof LayoutPlanningIndexRoute
  '/reports-and-analytics': typeof LayoutReportsAndAnalyticsIndexRoute
  '/dashboard': typeof LayoutDashboardIndexLazyRoute
  '/planning/demand-and-promotions': typeof LayoutPlanningDemandAndPromotionsIndexRoute
  '/work-area/inventory-balancing': typeof LayoutWorkAreaInventoryBalancingIndexRoute
  '/administration/app-filters': typeof LayoutAdministrationAppFiltersIndexLazyRoute
  '/administration/column-groups': typeof LayoutAdministrationColumnGroupsIndexLazyRoute
  '/administration/config-columns': typeof LayoutAdministrationConfigColumnsIndexLazyRoute
  '/administration/data-allocation': typeof LayoutAdministrationDataAllocationIndexLazyRoute
  '/administration/data-permission': typeof LayoutAdministrationDataPermissionIndexLazyRoute
  '/administration/environment-variables': typeof LayoutAdministrationEnvironmentVariablesIndexLazyRoute
  '/administration/general-parameters': typeof LayoutAdministrationGeneralParametersIndexLazyRoute
  '/administration/general-settings': typeof LayoutAdministrationGeneralSettingsIndexLazyRoute
  '/administration/roles-and-permissions': typeof LayoutAdministrationRolesAndPermissionsIndexLazyRoute
  '/administration/users': typeof LayoutAdministrationUsersIndexLazyRoute
  '/planning/events-calendar': typeof LayoutPlanningEventsCalendarIndexLazyRoute
  '/planning/invited-suppliers': typeof LayoutPlanningInvitedSuppliersIndexLazyRoute
  '/planning/procurement-calendar': typeof LayoutPlanningProcurementCalendarIndexLazyRoute
  '/planning/promotional-calendar': typeof LayoutPlanningPromotionalCalendarIndexLazyRoute
  '/planning/promotional-campaigns': typeof LayoutPlanningPromotionalCampaignsIndexLazyRoute
  '/planning/scenarios': typeof LayoutPlanningScenariosIndexLazyRoute
  '/reports-and-analytics/adherence-performance': typeof LayoutReportsAndAnalyticsAdherencePerformanceIndexLazyRoute
  '/reports-and-analytics/commercial-performance': typeof LayoutReportsAndAnalyticsCommercialPerformanceIndexLazyRoute
  '/reports-and-analytics/forecast-performance': typeof LayoutReportsAndAnalyticsForecastPerformanceIndexLazyRoute
  '/reports-and-analytics/overall-performance': typeof LayoutReportsAndAnalyticsOverallPerformanceIndexLazyRoute
  '/reports-and-analytics/stock-performance': typeof LayoutReportsAndAnalyticsStockPerformanceIndexLazyRoute
  '/work-area/price-definition': typeof LayoutWorkAreaPriceDefinitionIndexLazyRoute
  '/work-area/procurement': typeof LayoutWorkAreaProcurementIndexLazyRoute
  '/work-area/replenishment': typeof LayoutWorkAreaReplenishmentIndexLazyRoute
  '/work-area/surplurs-management': typeof LayoutWorkAreaSurplursManagementIndexLazyRoute
  '/planning/demand-and-promotions/details': typeof LayoutPlanningDemandAndPromotionsDetailsIndexRoute
  '/planning/scenarios/create': typeof LayoutPlanningScenariosCreateIndexRoute
  '/work-area/procurement/dashboard': typeof LayoutWorkAreaProcurementDashboardIndexRoute
  '/work-area/procurement/order': typeof LayoutWorkAreaProcurementOrderIndexRoute
  '/work-area/replenishment/order': typeof LayoutWorkAreaReplenishmentOrderIndexRoute
  '/administration/column-groups/$columnGroupId': typeof LayoutAdministrationColumnGroupsColumnGroupIdIndexLazyRoute
  '/administration/users/$userId': typeof LayoutAdministrationUsersUserIdIndexLazyRoute
  '/planning/promotional-campaigns/$campaignId': typeof LayoutPlanningPromotionalCampaignsCampaignIdIndexLazyRoute
  '/planning/scenarios/$scenarioId': typeof LayoutPlanningScenariosScenarioIdIndexLazyRoute
  '/reports-and-analytics/stock-performance/lost-sale': typeof LayoutReportsAndAnalyticsStockPerformanceLostSaleIndexLazyRoute
  '/reports-and-analytics/stock-performance/metrics': typeof LayoutReportsAndAnalyticsStockPerformanceMetricsIndexLazyRoute
  '/reports-and-analytics/stock-performance/overstock': typeof LayoutReportsAndAnalyticsStockPerformanceOverstockIndexLazyRoute
  '/reports-and-analytics/stock-performance/procurement': typeof LayoutReportsAndAnalyticsStockPerformanceProcurementIndexLazyRoute
  '/work-area/procurement/calendar': typeof LayoutWorkAreaProcurementCalendarIndexLazyRoute
  '/work-area/replenishment/suggestions': typeof LayoutWorkAreaReplenishmentSuggestionsIndexLazyRoute
  '/work-area/procurement/calendar/order': typeof LayoutWorkAreaProcurementCalendarOrderIndexRoute
  '/planning/promotional-campaigns/$campaignId/promotion/$promotionId': typeof LayoutPlanningPromotionalCampaignsCampaignIdPromotionPromotionIdIndexLazyRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '/login': typeof LoginRoute
  '': typeof LayoutLazyRouteWithChildren
  '/logout': typeof LogoutLazyRoute
  '/administration': typeof LayoutAdministrationRouteWithChildren
  '/work-area': typeof LayoutWorkAreaRouteWithChildren
  '/planning': typeof LayoutPlanningIndexRoute
  '/reports-and-analytics': typeof LayoutReportsAndAnalyticsIndexRoute
  '/dashboard': typeof LayoutDashboardIndexLazyRoute
  '/planning/demand-and-promotions': typeof LayoutPlanningDemandAndPromotionsIndexRoute
  '/work-area/inventory-balancing': typeof LayoutWorkAreaInventoryBalancingIndexRoute
  '/administration/app-filters': typeof LayoutAdministrationAppFiltersIndexLazyRoute
  '/administration/column-groups': typeof LayoutAdministrationColumnGroupsIndexLazyRoute
  '/administration/config-columns': typeof LayoutAdministrationConfigColumnsIndexLazyRoute
  '/administration/data-allocation': typeof LayoutAdministrationDataAllocationIndexLazyRoute
  '/administration/data-permission': typeof LayoutAdministrationDataPermissionIndexLazyRoute
  '/administration/environment-variables': typeof LayoutAdministrationEnvironmentVariablesIndexLazyRoute
  '/administration/general-parameters': typeof LayoutAdministrationGeneralParametersIndexLazyRoute
  '/administration/general-settings': typeof LayoutAdministrationGeneralSettingsIndexLazyRoute
  '/administration/roles-and-permissions': typeof LayoutAdministrationRolesAndPermissionsIndexLazyRoute
  '/administration/users': typeof LayoutAdministrationUsersIndexLazyRoute
  '/planning/events-calendar': typeof LayoutPlanningEventsCalendarIndexLazyRoute
  '/planning/invited-suppliers': typeof LayoutPlanningInvitedSuppliersIndexLazyRoute
  '/planning/procurement-calendar': typeof LayoutPlanningProcurementCalendarIndexLazyRoute
  '/planning/promotional-calendar': typeof LayoutPlanningPromotionalCalendarIndexLazyRoute
  '/planning/promotional-campaigns': typeof LayoutPlanningPromotionalCampaignsIndexLazyRoute
  '/planning/scenarios': typeof LayoutPlanningScenariosIndexLazyRoute
  '/reports-and-analytics/adherence-performance': typeof LayoutReportsAndAnalyticsAdherencePerformanceIndexLazyRoute
  '/reports-and-analytics/commercial-performance': typeof LayoutReportsAndAnalyticsCommercialPerformanceIndexLazyRoute
  '/reports-and-analytics/forecast-performance': typeof LayoutReportsAndAnalyticsForecastPerformanceIndexLazyRoute
  '/reports-and-analytics/overall-performance': typeof LayoutReportsAndAnalyticsOverallPerformanceIndexLazyRoute
  '/reports-and-analytics/stock-performance': typeof LayoutReportsAndAnalyticsStockPerformanceIndexLazyRoute
  '/work-area/price-definition': typeof LayoutWorkAreaPriceDefinitionIndexLazyRoute
  '/work-area/procurement': typeof LayoutWorkAreaProcurementIndexLazyRoute
  '/work-area/replenishment': typeof LayoutWorkAreaReplenishmentIndexLazyRoute
  '/work-area/surplurs-management': typeof LayoutWorkAreaSurplursManagementIndexLazyRoute
  '/planning/demand-and-promotions/details': typeof LayoutPlanningDemandAndPromotionsDetailsIndexRoute
  '/planning/scenarios/create': typeof LayoutPlanningScenariosCreateIndexRoute
  '/work-area/procurement/dashboard': typeof LayoutWorkAreaProcurementDashboardIndexRoute
  '/work-area/procurement/order': typeof LayoutWorkAreaProcurementOrderIndexRoute
  '/work-area/replenishment/order': typeof LayoutWorkAreaReplenishmentOrderIndexRoute
  '/administration/column-groups/$columnGroupId': typeof LayoutAdministrationColumnGroupsColumnGroupIdIndexLazyRoute
  '/administration/users/$userId': typeof LayoutAdministrationUsersUserIdIndexLazyRoute
  '/planning/promotional-campaigns/$campaignId': typeof LayoutPlanningPromotionalCampaignsCampaignIdIndexLazyRoute
  '/planning/scenarios/$scenarioId': typeof LayoutPlanningScenariosScenarioIdIndexLazyRoute
  '/reports-and-analytics/stock-performance/lost-sale': typeof LayoutReportsAndAnalyticsStockPerformanceLostSaleIndexLazyRoute
  '/reports-and-analytics/stock-performance/metrics': typeof LayoutReportsAndAnalyticsStockPerformanceMetricsIndexLazyRoute
  '/reports-and-analytics/stock-performance/overstock': typeof LayoutReportsAndAnalyticsStockPerformanceOverstockIndexLazyRoute
  '/reports-and-analytics/stock-performance/procurement': typeof LayoutReportsAndAnalyticsStockPerformanceProcurementIndexLazyRoute
  '/work-area/procurement/calendar': typeof LayoutWorkAreaProcurementCalendarIndexLazyRoute
  '/work-area/replenishment/suggestions': typeof LayoutWorkAreaReplenishmentSuggestionsIndexLazyRoute
  '/work-area/procurement/calendar/order': typeof LayoutWorkAreaProcurementCalendarOrderIndexRoute
  '/planning/promotional-campaigns/$campaignId/promotion/$promotionId': typeof LayoutPlanningPromotionalCampaignsCampaignIdPromotionPromotionIdIndexLazyRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/login': typeof LoginRoute
  '/_layout': typeof LayoutLazyRouteWithChildren
  '/logout': typeof LogoutLazyRoute
  '/_layout/administration': typeof LayoutAdministrationRouteWithChildren
  '/_layout/work-area': typeof LayoutWorkAreaRouteWithChildren
  '/_layout/planning/': typeof LayoutPlanningIndexRoute
  '/_layout/reports-and-analytics/': typeof LayoutReportsAndAnalyticsIndexRoute
  '/_layout/dashboard/': typeof LayoutDashboardIndexLazyRoute
  '/_layout/planning/demand-and-promotions/': typeof LayoutPlanningDemandAndPromotionsIndexRoute
  '/_layout/work-area/inventory-balancing/': typeof LayoutWorkAreaInventoryBalancingIndexRoute
  '/_layout/administration/app-filters/': typeof LayoutAdministrationAppFiltersIndexLazyRoute
  '/_layout/administration/column-groups/': typeof LayoutAdministrationColumnGroupsIndexLazyRoute
  '/_layout/administration/config-columns/': typeof LayoutAdministrationConfigColumnsIndexLazyRoute
  '/_layout/administration/data-allocation/': typeof LayoutAdministrationDataAllocationIndexLazyRoute
  '/_layout/administration/data-permission/': typeof LayoutAdministrationDataPermissionIndexLazyRoute
  '/_layout/administration/environment-variables/': typeof LayoutAdministrationEnvironmentVariablesIndexLazyRoute
  '/_layout/administration/general-parameters/': typeof LayoutAdministrationGeneralParametersIndexLazyRoute
  '/_layout/administration/general-settings/': typeof LayoutAdministrationGeneralSettingsIndexLazyRoute
  '/_layout/administration/roles-and-permissions/': typeof LayoutAdministrationRolesAndPermissionsIndexLazyRoute
  '/_layout/administration/users/': typeof LayoutAdministrationUsersIndexLazyRoute
  '/_layout/planning/events-calendar/': typeof LayoutPlanningEventsCalendarIndexLazyRoute
  '/_layout/planning/invited-suppliers/': typeof LayoutPlanningInvitedSuppliersIndexLazyRoute
  '/_layout/planning/procurement-calendar/': typeof LayoutPlanningProcurementCalendarIndexLazyRoute
  '/_layout/planning/promotional-calendar/': typeof LayoutPlanningPromotionalCalendarIndexLazyRoute
  '/_layout/planning/promotional-campaigns/': typeof LayoutPlanningPromotionalCampaignsIndexLazyRoute
  '/_layout/planning/scenarios/': typeof LayoutPlanningScenariosIndexLazyRoute
  '/_layout/reports-and-analytics/adherence-performance/': typeof LayoutReportsAndAnalyticsAdherencePerformanceIndexLazyRoute
  '/_layout/reports-and-analytics/commercial-performance/': typeof LayoutReportsAndAnalyticsCommercialPerformanceIndexLazyRoute
  '/_layout/reports-and-analytics/forecast-performance/': typeof LayoutReportsAndAnalyticsForecastPerformanceIndexLazyRoute
  '/_layout/reports-and-analytics/overall-performance/': typeof LayoutReportsAndAnalyticsOverallPerformanceIndexLazyRoute
  '/_layout/reports-and-analytics/stock-performance/': typeof LayoutReportsAndAnalyticsStockPerformanceIndexLazyRoute
  '/_layout/work-area/price-definition/': typeof LayoutWorkAreaPriceDefinitionIndexLazyRoute
  '/_layout/work-area/procurement/': typeof LayoutWorkAreaProcurementIndexLazyRoute
  '/_layout/work-area/replenishment/': typeof LayoutWorkAreaReplenishmentIndexLazyRoute
  '/_layout/work-area/surplurs-management/': typeof LayoutWorkAreaSurplursManagementIndexLazyRoute
  '/_layout/planning/demand-and-promotions/details/': typeof LayoutPlanningDemandAndPromotionsDetailsIndexRoute
  '/_layout/planning/scenarios/create/': typeof LayoutPlanningScenariosCreateIndexRoute
  '/_layout/work-area/procurement/dashboard/': typeof LayoutWorkAreaProcurementDashboardIndexRoute
  '/_layout/work-area/procurement/order/': typeof LayoutWorkAreaProcurementOrderIndexRoute
  '/_layout/work-area/replenishment/order/': typeof LayoutWorkAreaReplenishmentOrderIndexRoute
  '/_layout/administration/column-groups/$columnGroupId/': typeof LayoutAdministrationColumnGroupsColumnGroupIdIndexLazyRoute
  '/_layout/administration/users/$userId/': typeof LayoutAdministrationUsersUserIdIndexLazyRoute
  '/_layout/planning/promotional-campaigns/$campaignId/': typeof LayoutPlanningPromotionalCampaignsCampaignIdIndexLazyRoute
  '/_layout/planning/scenarios/$scenarioId/': typeof LayoutPlanningScenariosScenarioIdIndexLazyRoute
  '/_layout/reports-and-analytics/stock-performance/lost-sale/': typeof LayoutReportsAndAnalyticsStockPerformanceLostSaleIndexLazyRoute
  '/_layout/reports-and-analytics/stock-performance/metrics/': typeof LayoutReportsAndAnalyticsStockPerformanceMetricsIndexLazyRoute
  '/_layout/reports-and-analytics/stock-performance/overstock/': typeof LayoutReportsAndAnalyticsStockPerformanceOverstockIndexLazyRoute
  '/_layout/reports-and-analytics/stock-performance/procurement/': typeof LayoutReportsAndAnalyticsStockPerformanceProcurementIndexLazyRoute
  '/_layout/work-area/procurement/calendar/': typeof LayoutWorkAreaProcurementCalendarIndexLazyRoute
  '/_layout/work-area/replenishment/suggestions/': typeof LayoutWorkAreaReplenishmentSuggestionsIndexLazyRoute
  '/_layout/work-area/procurement/calendar/order/': typeof LayoutWorkAreaProcurementCalendarOrderIndexRoute
  '/_layout/planning/promotional-campaigns/$campaignId/promotion/$promotionId/': typeof LayoutPlanningPromotionalCampaignsCampaignIdPromotionPromotionIdIndexLazyRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/login'
    | ''
    | '/logout'
    | '/administration'
    | '/work-area'
    | '/planning'
    | '/reports-and-analytics'
    | '/dashboard'
    | '/planning/demand-and-promotions'
    | '/work-area/inventory-balancing'
    | '/administration/app-filters'
    | '/administration/column-groups'
    | '/administration/config-columns'
    | '/administration/data-allocation'
    | '/administration/data-permission'
    | '/administration/environment-variables'
    | '/administration/general-parameters'
    | '/administration/general-settings'
    | '/administration/roles-and-permissions'
    | '/administration/users'
    | '/planning/events-calendar'
    | '/planning/invited-suppliers'
    | '/planning/procurement-calendar'
    | '/planning/promotional-calendar'
    | '/planning/promotional-campaigns'
    | '/planning/scenarios'
    | '/reports-and-analytics/adherence-performance'
    | '/reports-and-analytics/commercial-performance'
    | '/reports-and-analytics/forecast-performance'
    | '/reports-and-analytics/overall-performance'
    | '/reports-and-analytics/stock-performance'
    | '/work-area/price-definition'
    | '/work-area/procurement'
    | '/work-area/replenishment'
    | '/work-area/surplurs-management'
    | '/planning/demand-and-promotions/details'
    | '/planning/scenarios/create'
    | '/work-area/procurement/dashboard'
    | '/work-area/procurement/order'
    | '/work-area/replenishment/order'
    | '/administration/column-groups/$columnGroupId'
    | '/administration/users/$userId'
    | '/planning/promotional-campaigns/$campaignId'
    | '/planning/scenarios/$scenarioId'
    | '/reports-and-analytics/stock-performance/lost-sale'
    | '/reports-and-analytics/stock-performance/metrics'
    | '/reports-and-analytics/stock-performance/overstock'
    | '/reports-and-analytics/stock-performance/procurement'
    | '/work-area/procurement/calendar'
    | '/work-area/replenishment/suggestions'
    | '/work-area/procurement/calendar/order'
    | '/planning/promotional-campaigns/$campaignId/promotion/$promotionId'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/login'
    | ''
    | '/logout'
    | '/administration'
    | '/work-area'
    | '/planning'
    | '/reports-and-analytics'
    | '/dashboard'
    | '/planning/demand-and-promotions'
    | '/work-area/inventory-balancing'
    | '/administration/app-filters'
    | '/administration/column-groups'
    | '/administration/config-columns'
    | '/administration/data-allocation'
    | '/administration/data-permission'
    | '/administration/environment-variables'
    | '/administration/general-parameters'
    | '/administration/general-settings'
    | '/administration/roles-and-permissions'
    | '/administration/users'
    | '/planning/events-calendar'
    | '/planning/invited-suppliers'
    | '/planning/procurement-calendar'
    | '/planning/promotional-calendar'
    | '/planning/promotional-campaigns'
    | '/planning/scenarios'
    | '/reports-and-analytics/adherence-performance'
    | '/reports-and-analytics/commercial-performance'
    | '/reports-and-analytics/forecast-performance'
    | '/reports-and-analytics/overall-performance'
    | '/reports-and-analytics/stock-performance'
    | '/work-area/price-definition'
    | '/work-area/procurement'
    | '/work-area/replenishment'
    | '/work-area/surplurs-management'
    | '/planning/demand-and-promotions/details'
    | '/planning/scenarios/create'
    | '/work-area/procurement/dashboard'
    | '/work-area/procurement/order'
    | '/work-area/replenishment/order'
    | '/administration/column-groups/$columnGroupId'
    | '/administration/users/$userId'
    | '/planning/promotional-campaigns/$campaignId'
    | '/planning/scenarios/$scenarioId'
    | '/reports-and-analytics/stock-performance/lost-sale'
    | '/reports-and-analytics/stock-performance/metrics'
    | '/reports-and-analytics/stock-performance/overstock'
    | '/reports-and-analytics/stock-performance/procurement'
    | '/work-area/procurement/calendar'
    | '/work-area/replenishment/suggestions'
    | '/work-area/procurement/calendar/order'
    | '/planning/promotional-campaigns/$campaignId/promotion/$promotionId'
  id:
    | '__root__'
    | '/'
    | '/login'
    | '/_layout'
    | '/logout'
    | '/_layout/administration'
    | '/_layout/work-area'
    | '/_layout/planning/'
    | '/_layout/reports-and-analytics/'
    | '/_layout/dashboard/'
    | '/_layout/planning/demand-and-promotions/'
    | '/_layout/work-area/inventory-balancing/'
    | '/_layout/administration/app-filters/'
    | '/_layout/administration/column-groups/'
    | '/_layout/administration/config-columns/'
    | '/_layout/administration/data-allocation/'
    | '/_layout/administration/data-permission/'
    | '/_layout/administration/environment-variables/'
    | '/_layout/administration/general-parameters/'
    | '/_layout/administration/general-settings/'
    | '/_layout/administration/roles-and-permissions/'
    | '/_layout/administration/users/'
    | '/_layout/planning/events-calendar/'
    | '/_layout/planning/invited-suppliers/'
    | '/_layout/planning/procurement-calendar/'
    | '/_layout/planning/promotional-calendar/'
    | '/_layout/planning/promotional-campaigns/'
    | '/_layout/planning/scenarios/'
    | '/_layout/reports-and-analytics/adherence-performance/'
    | '/_layout/reports-and-analytics/commercial-performance/'
    | '/_layout/reports-and-analytics/forecast-performance/'
    | '/_layout/reports-and-analytics/overall-performance/'
    | '/_layout/reports-and-analytics/stock-performance/'
    | '/_layout/work-area/price-definition/'
    | '/_layout/work-area/procurement/'
    | '/_layout/work-area/replenishment/'
    | '/_layout/work-area/surplurs-management/'
    | '/_layout/planning/demand-and-promotions/details/'
    | '/_layout/planning/scenarios/create/'
    | '/_layout/work-area/procurement/dashboard/'
    | '/_layout/work-area/procurement/order/'
    | '/_layout/work-area/replenishment/order/'
    | '/_layout/administration/column-groups/$columnGroupId/'
    | '/_layout/administration/users/$userId/'
    | '/_layout/planning/promotional-campaigns/$campaignId/'
    | '/_layout/planning/scenarios/$scenarioId/'
    | '/_layout/reports-and-analytics/stock-performance/lost-sale/'
    | '/_layout/reports-and-analytics/stock-performance/metrics/'
    | '/_layout/reports-and-analytics/stock-performance/overstock/'
    | '/_layout/reports-and-analytics/stock-performance/procurement/'
    | '/_layout/work-area/procurement/calendar/'
    | '/_layout/work-area/replenishment/suggestions/'
    | '/_layout/work-area/procurement/calendar/order/'
    | '/_layout/planning/promotional-campaigns/$campaignId/promotion/$promotionId/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  LoginRoute: typeof LoginRoute
  LayoutLazyRoute: typeof LayoutLazyRouteWithChildren
  LogoutLazyRoute: typeof LogoutLazyRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  LoginRoute: LoginRoute,
  LayoutLazyRoute: LayoutLazyRouteWithChildren,
  LogoutLazyRoute: LogoutLazyRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/login",
        "/_layout",
        "/logout"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/login": {
      "filePath": "login.tsx"
    },
    "/_layout": {
      "filePath": "_layout.lazy.tsx",
      "children": [
        "/_layout/administration",
        "/_layout/work-area",
        "/_layout/planning/",
        "/_layout/reports-and-analytics/",
        "/_layout/dashboard/",
        "/_layout/planning/demand-and-promotions/",
        "/_layout/planning/events-calendar/",
        "/_layout/planning/invited-suppliers/",
        "/_layout/planning/procurement-calendar/",
        "/_layout/planning/promotional-calendar/",
        "/_layout/planning/promotional-campaigns/",
        "/_layout/planning/scenarios/",
        "/_layout/reports-and-analytics/adherence-performance/",
        "/_layout/reports-and-analytics/commercial-performance/",
        "/_layout/reports-and-analytics/forecast-performance/",
        "/_layout/reports-and-analytics/overall-performance/",
        "/_layout/reports-and-analytics/stock-performance/",
        "/_layout/planning/demand-and-promotions/details/",
        "/_layout/planning/scenarios/create/",
        "/_layout/planning/promotional-campaigns/$campaignId/",
        "/_layout/planning/scenarios/$scenarioId/",
        "/_layout/reports-and-analytics/stock-performance/lost-sale/",
        "/_layout/reports-and-analytics/stock-performance/metrics/",
        "/_layout/reports-and-analytics/stock-performance/overstock/",
        "/_layout/reports-and-analytics/stock-performance/procurement/",
        "/_layout/planning/promotional-campaigns/$campaignId/promotion/$promotionId/"
      ]
    },
    "/logout": {
      "filePath": "logout.lazy.tsx"
    },
    "/_layout/administration": {
      "filePath": "_layout.administration.tsx",
      "parent": "/_layout",
      "children": [
        "/_layout/administration/app-filters/",
        "/_layout/administration/column-groups/",
        "/_layout/administration/config-columns/",
        "/_layout/administration/data-allocation/",
        "/_layout/administration/data-permission/",
        "/_layout/administration/environment-variables/",
        "/_layout/administration/general-parameters/",
        "/_layout/administration/general-settings/",
        "/_layout/administration/roles-and-permissions/",
        "/_layout/administration/users/",
        "/_layout/administration/column-groups/$columnGroupId/",
        "/_layout/administration/users/$userId/"
      ]
    },
    "/_layout/work-area": {
      "filePath": "_layout.work-area.tsx",
      "parent": "/_layout",
      "children": [
        "/_layout/work-area/inventory-balancing/",
        "/_layout/work-area/price-definition/",
        "/_layout/work-area/procurement/",
        "/_layout/work-area/replenishment/",
        "/_layout/work-area/surplurs-management/",
        "/_layout/work-area/procurement/dashboard/",
        "/_layout/work-area/procurement/order/",
        "/_layout/work-area/replenishment/order/",
        "/_layout/work-area/procurement/calendar/",
        "/_layout/work-area/replenishment/suggestions/",
        "/_layout/work-area/procurement/calendar/order/"
      ]
    },
    "/_layout/planning/": {
      "filePath": "_layout.planning.index.tsx",
      "parent": "/_layout"
    },
    "/_layout/reports-and-analytics/": {
      "filePath": "_layout.reports-and-analytics.index.tsx",
      "parent": "/_layout"
    },
    "/_layout/dashboard/": {
      "filePath": "_layout.dashboard.index.lazy.tsx",
      "parent": "/_layout"
    },
    "/_layout/planning/demand-and-promotions/": {
      "filePath": "_layout.planning.demand-and-promotions.index.tsx",
      "parent": "/_layout"
    },
    "/_layout/work-area/inventory-balancing/": {
      "filePath": "_layout.work-area.inventory-balancing.index.tsx",
      "parent": "/_layout/work-area"
    },
    "/_layout/administration/app-filters/": {
      "filePath": "_layout.administration.app-filters.index.lazy.tsx",
      "parent": "/_layout/administration"
    },
    "/_layout/administration/column-groups/": {
      "filePath": "_layout.administration.column-groups.index.lazy.tsx",
      "parent": "/_layout/administration"
    },
    "/_layout/administration/config-columns/": {
      "filePath": "_layout.administration.config-columns.index.lazy.tsx",
      "parent": "/_layout/administration"
    },
    "/_layout/administration/data-allocation/": {
      "filePath": "_layout.administration.data-allocation.index.lazy.tsx",
      "parent": "/_layout/administration"
    },
    "/_layout/administration/data-permission/": {
      "filePath": "_layout.administration.data-permission.index.lazy.tsx",
      "parent": "/_layout/administration"
    },
    "/_layout/administration/environment-variables/": {
      "filePath": "_layout.administration.environment-variables.index.lazy.tsx",
      "parent": "/_layout/administration"
    },
    "/_layout/administration/general-parameters/": {
      "filePath": "_layout.administration.general-parameters.index.lazy.tsx",
      "parent": "/_layout/administration"
    },
    "/_layout/administration/general-settings/": {
      "filePath": "_layout.administration.general-settings.index.lazy.tsx",
      "parent": "/_layout/administration"
    },
    "/_layout/administration/roles-and-permissions/": {
      "filePath": "_layout.administration.roles-and-permissions.index.lazy.tsx",
      "parent": "/_layout/administration"
    },
    "/_layout/administration/users/": {
      "filePath": "_layout.administration.users.index.lazy.tsx",
      "parent": "/_layout/administration"
    },
    "/_layout/planning/events-calendar/": {
      "filePath": "_layout.planning.events-calendar.index.lazy.tsx",
      "parent": "/_layout"
    },
    "/_layout/planning/invited-suppliers/": {
      "filePath": "_layout.planning.invited-suppliers.index.lazy.tsx",
      "parent": "/_layout"
    },
    "/_layout/planning/procurement-calendar/": {
      "filePath": "_layout.planning.procurement-calendar.index.lazy.tsx",
      "parent": "/_layout"
    },
    "/_layout/planning/promotional-calendar/": {
      "filePath": "_layout.planning.promotional-calendar.index.lazy.tsx",
      "parent": "/_layout"
    },
    "/_layout/planning/promotional-campaigns/": {
      "filePath": "_layout.planning.promotional-campaigns.index.lazy.tsx",
      "parent": "/_layout"
    },
    "/_layout/planning/scenarios/": {
      "filePath": "_layout.planning.scenarios.index.lazy.tsx",
      "parent": "/_layout"
    },
    "/_layout/reports-and-analytics/adherence-performance/": {
      "filePath": "_layout.reports-and-analytics.adherence-performance.index.lazy.tsx",
      "parent": "/_layout"
    },
    "/_layout/reports-and-analytics/commercial-performance/": {
      "filePath": "_layout.reports-and-analytics.commercial-performance.index.lazy.tsx",
      "parent": "/_layout"
    },
    "/_layout/reports-and-analytics/forecast-performance/": {
      "filePath": "_layout.reports-and-analytics.forecast-performance.index.lazy.tsx",
      "parent": "/_layout"
    },
    "/_layout/reports-and-analytics/overall-performance/": {
      "filePath": "_layout.reports-and-analytics.overall-performance.index.lazy.tsx",
      "parent": "/_layout"
    },
    "/_layout/reports-and-analytics/stock-performance/": {
      "filePath": "_layout.reports-and-analytics.stock-performance.index.lazy.tsx",
      "parent": "/_layout"
    },
    "/_layout/work-area/price-definition/": {
      "filePath": "_layout.work-area.price-definition.index.lazy.tsx",
      "parent": "/_layout/work-area"
    },
    "/_layout/work-area/procurement/": {
      "filePath": "_layout.work-area.procurement.index.lazy.tsx",
      "parent": "/_layout/work-area"
    },
    "/_layout/work-area/replenishment/": {
      "filePath": "_layout.work-area.replenishment.index.lazy.tsx",
      "parent": "/_layout/work-area"
    },
    "/_layout/work-area/surplurs-management/": {
      "filePath": "_layout.work-area.surplurs-management.index.lazy.tsx",
      "parent": "/_layout/work-area"
    },
    "/_layout/planning/demand-and-promotions/details/": {
      "filePath": "_layout.planning.demand-and-promotions.details.index.tsx",
      "parent": "/_layout"
    },
    "/_layout/planning/scenarios/create/": {
      "filePath": "_layout.planning.scenarios.create.index.tsx",
      "parent": "/_layout"
    },
    "/_layout/work-area/procurement/dashboard/": {
      "filePath": "_layout.work-area.procurement.dashboard.index.tsx",
      "parent": "/_layout/work-area"
    },
    "/_layout/work-area/procurement/order/": {
      "filePath": "_layout.work-area.procurement.order.index.tsx",
      "parent": "/_layout/work-area"
    },
    "/_layout/work-area/replenishment/order/": {
      "filePath": "_layout.work-area.replenishment.order.index.tsx",
      "parent": "/_layout/work-area"
    },
    "/_layout/administration/column-groups/$columnGroupId/": {
      "filePath": "_layout.administration.column-groups.$columnGroupId.index.lazy.tsx",
      "parent": "/_layout/administration"
    },
    "/_layout/administration/users/$userId/": {
      "filePath": "_layout.administration.users.$userId.index.lazy.tsx",
      "parent": "/_layout/administration"
    },
    "/_layout/planning/promotional-campaigns/$campaignId/": {
      "filePath": "_layout.planning.promotional-campaigns.$campaignId.index.lazy.tsx",
      "parent": "/_layout"
    },
    "/_layout/planning/scenarios/$scenarioId/": {
      "filePath": "_layout.planning.scenarios.$scenarioId.index.lazy.tsx",
      "parent": "/_layout"
    },
    "/_layout/reports-and-analytics/stock-performance/lost-sale/": {
      "filePath": "_layout.reports-and-analytics.stock-performance.lost-sale.index.lazy.tsx",
      "parent": "/_layout"
    },
    "/_layout/reports-and-analytics/stock-performance/metrics/": {
      "filePath": "_layout.reports-and-analytics.stock-performance.metrics.index.lazy.tsx",
      "parent": "/_layout"
    },
    "/_layout/reports-and-analytics/stock-performance/overstock/": {
      "filePath": "_layout.reports-and-analytics.stock-performance.overstock.index.lazy.tsx",
      "parent": "/_layout"
    },
    "/_layout/reports-and-analytics/stock-performance/procurement/": {
      "filePath": "_layout.reports-and-analytics.stock-performance.procurement.index.lazy.tsx",
      "parent": "/_layout"
    },
    "/_layout/work-area/procurement/calendar/": {
      "filePath": "_layout.work-area.procurement.calendar.index.lazy.tsx",
      "parent": "/_layout/work-area"
    },
    "/_layout/work-area/replenishment/suggestions/": {
      "filePath": "_layout.work-area.replenishment.suggestions.index.lazy.tsx",
      "parent": "/_layout/work-area"
    },
    "/_layout/work-area/procurement/calendar/order/": {
      "filePath": "_layout.work-area.procurement.calendar.order.index.tsx",
      "parent": "/_layout/work-area"
    },
    "/_layout/planning/promotional-campaigns/$campaignId/promotion/$promotionId/": {
      "filePath": "_layout.planning.promotional-campaigns.$campaignId.promotion.$promotionId.index.lazy.tsx",
      "parent": "/_layout"
    }
  }
}
ROUTE_MANIFEST_END */
